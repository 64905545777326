import styled from "styled-components";
import Move from "../../components/layout/Home/Move";
import Footer from "../../components/layout/Footer";
import FeatureCard from "../../components/common/FeatureCard";
import Group from "../../assets//Group.svg";
import HowItWorks from "../../components/layout/Home/HowItWorks";
import usePageTitle from "../../components/hooks/usePageTitle";

const Services = () => {
  usePageTitle("Our Services");

  const offerListOne = [
    { id: 1, text: "It is a long established fact that a reader will be distracted" },
    { id: 2, text: "It is a long established fact that a reader will be distracted" },
    { id: 3, text: "It is a long established fact that a reader will be distracted" },
    { id: 4, text: "It is a long established fact that a reader will be distracted" },
    { id: 5, text: "It is a long established fact that a reader will be distracted" },
    { id: 6, text: "It is a long established fact that a reader will be distracted" },
  ];

  const offerListTwo = [
    { id: 1, text: "It is a long established fact that a reader will be distracted" },
    { id: 2, text: "It is a long established fact that a reader will be distracted" },
    { id: 3, text: "It is a long established fact that a reader will be distracted" },
    { id: 4, text: "It is a long established fact that a reader will be distracted" },
    { id: 5, text: "It is a long established fact that a reader will be distracted" },
    { id: 6, text: "It is a long established fact that a reader will be distracted" },
  ];
  return (
    <>
      <Inner>
        <Intro>
          <div className={"text-container"}>
            <h2>Our Services</h2>
            <p>
              At our company, we are committed to providing top-notch haulage, land freight transportation, track
              logistics and income for companies. With years of experience in the industry, we understand the importance
              of reliable transportation and logistics solutions for businesses of all sizes.
            </p>
          </div>
          <div className={"image-container"}>
            <img src={"/service-pic.png"} alt={"service"} />
          </div>
        </Intro>
        <Schedule>
          <div className={"head"}>
            <h2>We make it easy to schedule</h2>
            <p>Schedule you cargo from pickup to drop off with just one</p>
            <p className={"p2"}>click, It's even better, we take care of everything in between</p>
          </div>
          <CardContainer>
            <Item>
              <FeatureCard
                center
                title="On-demand trucking"
                info="Our on-demand trucking service allows you to book a truck in real-time and get your cargo delivered quickly and efficiently"
              />
            </Item>
            <Item>
              <FeatureCard
                center
                title="Scheduled trips"
                info="If you need to move cargo on a regular basis, we offer scheduled trips to help you plan ahead and ensure your cargo is delivered on time."
              />
            </Item>
            <Item>
              <FeatureCard
                center
                title="Fleet management"
                info="If you own a fleet of trucks, we offer a fleet management service that helps you track your vehicles, manage your drivers, and optimize your operations."
              />
            </Item>
          </CardContainer>
        </Schedule>
        {/* <Offer>
          <h2>With GoTruck you get</h2>
          <OfferContainer>
            <div className={"offer-list"}>
              {offerListOne.map((item) => {
                return (
                  <OfferItem key={item.id}>
                    <img src={Group} alt={"offer-item"} />
                    <p>{item.text}</p>
                  </OfferItem>
                );
              })}
            </div>
            <div className={"offer-list"}>
              {offerListTwo.map((item) => {
                return (
                  <OfferItem key={item.id}>
                    <img src={Group} alt={"offer-item"} />
                    <p>{item.text}</p>
                  </OfferItem>
                );
              })}
            </div>
          </OfferContainer>
        </Offer> */}
      </Inner>

      <div className="space-y-20 lg:space-y-32">
        <HowItWorks />
        <Move width={"100%"} />
      </div>

      <Footer width={"90%"} />
    </>
  );
};

const Inner = styled.div`
  p {
    color: #888888;
  }
`;
const Intro = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  .text-container,
  .image-container {
    width: 46%;
  }

  .text-container {
    padding: 0 20px;
  }

  .image-container {
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    font-size: 40px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 18px;
    margin: 0;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }

  @media (max-width: 900px) {
    p {
      font-size: 15px;
    }
  }

  @media (max-width: 850px) {
    display: block;

    h2,
    p {
      text-align: center;
    }

    .text-container,
    .image-container {
      width: 100%;
      padding-top: 30px;
    }
  }
`;

const Schedule = styled.div`
  width: 100%;
  padding: 25px 0;
  min-height: 60vh;
  background: rgb(255, 245, 238);
  background: linear-gradient(180deg, rgba(255, 245, 238, 1) 69%, rgba(255, 255, 255, 1) 70%);

  h2 {
    font-size: 40px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  p {
    font-size: 15px;
  }
  .p2 {
    margin-top: -15px;
  }

  .head {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 13px;
    }
  }
`;

const CardContainer = styled.div`
  width: 90%;
  margin: 80px auto 40px;
  display: flex;

  @media (max-width: 830px) {
    display: block;
  }
`;

const Item = styled.div`
  width: 30%;
  margin: 0 auto;
  display: flex;

  p {
    font-size: 13px;
  }

  @media (max-width: 830px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Offer = styled.div`
  min-height: 60vh;
  margin-bottom: 40px;
  h2 {
    font-size: 40px;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
  }

  p {
    font-size: 15px;
    margin: 0;
  }

  img {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    h2 {
      font-size: 25px;
    }
  }
`;

const OfferContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .offer-list {
    width: 48%;
  }

  @media (max-width: 700px) {
    width: 90%;
    display: block;

    .offer-list {
      width: 100%;
    }
  }
`;

const OfferItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 20px;

  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export default Services;
