import styled from "styled-components";
import Footer from "../../components/layout/Footer";
import Move from "../../components/layout/Home/Move";
import usePageTitle from "../../components/hooks/usePageTitle";

const AboutUs = () => {
  usePageTitle("About Us");

  return (
    <>
      {/*<Navbar />*/}
      <Inner>
        <About>
          <div className={"content-container"}>
            <h1>About GoTruck</h1>
            <p>
              GoTruck is a mobile and web platform where you can order trucks to move your goods or other items. Our
              platform provides end-to-end haulage operations for cargo owners, truck owners and cargo recipients to do
              business of cargo movement seamlessly.
            </p>
            <h3>For Shippers</h3>
            <p>
              With live tracking, automated matching, pricing and scheduling of trucks, we significantly reduce
              shippers’ total costs and we get each load delivered safely and on time.
            </p>
            <h3>For Carriers</h3>
            <p>
              With up upfront pricing and instant booking. We make it easy for Carriers to find trips and haul loads
              that keep their trucks full.
            </p>
          </div>
          <div className={"img-container"}>
            <img src={"/about.png"} alt={"about-us"} />
          </div>
        </About>
        <Mission>
          <h2>Mission</h2>
          <p>To move goods faster and efficiently.</p>
        </Mission>
        <Mission>
          <h2>Vision</h2>
          <p>To be the one-stop shop to move, clear, store and deliver goods all in one day</p>
        </Mission>

        <Values>
          <div className={"inner"}>
            <div className="img-container hidden md:block">
              <img src="/images/about-bg.png" alt="team-pic" />
            </div>
            <div className="text-container">
              <h2>Values & Principles </h2>
              <ul>
                <li>
                  <h3>Focus on the user </h3>
                  <p>
                    Our users are the most important aspect of our business. When making decisions or selecting the next
                    item to tackle, we always ask; are we solving the right problem? Are we making our users lives
                    significantly better? If the answer is yes, then every other thing will follow
                  </p>
                </li>
                <li>
                  <h3>Uphold the mission</h3>
                  <p>
                    If we can achieve our goal, we will be able to play a part in bettering the lives of everyday
                    Africans. Moving goods faster means reducing the cost, and empowering businesses which ultimately
                    leads to significantly lower cost of goods and living for the consumers.{" "}
                  </p>
                </li>
                <li>
                  <h3>Succeed or learn</h3>
                  <p>
                    Innovation is at the core of what we do. Which means we are going to take risks and bet big.
                    Sometimes we will succeed, and sometimes we will not. It is important for us to ensure that learning
                    is never lost by bringing the lessons to next attempts.{" "}
                  </p>
                </li>
                <li>
                  <h3>Do the right thing</h3>
                  <p>
                    At the end of the day, Go Truck is a business and one of the primary focal points is to generate
                    revenue. However, it’s important to ensure that every decision we make to drive that effort is
                    ethical, honest, and in full compliance with the law.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Values>
        <Support>
          <h2>Backed by Top firms and Industry leaders</h2>
          <marquee behavior={"alternate"}>
            <div className={"img-container"}>
              <img src={"/comp1.png"} alt={"pic"} />
              <img src={"/comp2.png"} alt={"pic"} />
              <img src={"/comp3.png"} alt={"pic"} />
              <img src={"/comp4.png"} alt={"pic"} />
              <img src={"/comp5.png"} alt={"pic"} />
            </div>
          </marquee>
        </Support>
      </Inner>
      <Move width={"100%"} />
      <Footer width={"90%"} />
    </>
  );
};

const Inner = styled.div`
  img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 40px;
  }

  p {
    font-size: 17px;
  }
`;

const About = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px 0 0;

  p {
    color: #888888;
  }

  h1 {
    font-size: 40px;
    color: #344054;
  }

  .content-container {
    h3 {
      font-size: 20px;
      color: #344054;
    }
  }

  .img-container,
  .content-container {
    flex: 1;
  }

  .img-container {
    display: flex;
    justify-content: flex-end;
  }

  .img-container img {
    width: 80%;
    height: 80%;
  }

  @media (max-width: 700px) {
    display: block;
    text-align: center;
    padding-top: 50px;

    .content-container {
      margin-bottom: 50px;
    }

    .img-container {
      justify-content: center;
    }

    .img-container img {
      width: 80%;
      height: 80%;
    }
  }
`;

const Mission = styled.div`
    text-align: center;
    width: 90%;
    margin: 40px auto;

  h2{
    font-size: 30px;
    margin-bottom: -5px;
    color: #344054
  }
  
  p{
    width: 60%;
    color: #888888;
    margin: 0 auto;
  }
  
  @media (max-width: 1000px){
    p{
      width: 80%;
    }

    @media (max-width: 500px){
      p{
        width: 100%;
      }
  }
`;

const Values = styled.div`
  background: #fff5ee;

  .inner {
    //width: 90%;
    display: flex;
    gap: 40px;
    margin: 50px auto;
    align-items: flex-start;
  }

  .img-container {
    margin: 0;

    img {
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text-container {
    width: 60%;
    padding: 40px 40px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      color: #888888;
      margin: 0 auto;
      //margin-bottom: 5px;
      font-size: 17px;
      list-style: none;
    }

    h2 {
      font-size: 35px;
      color: #344054;
    }

    h3 {
      color: #344054;
    }
  }

  @media (max-width: 730px) {
    .inner {
      flex-direction: column-reverse;
    }

    .img-container,
    .text-container {
      width: 100%;
      //text-align: center;
      padding: 0;
    }

    .text-container {
      padding: 20px;
    }
  }
`;

const Support = styled.div`
  //width: 90%;
  margin: 0 auto;

  .img-container {
    display: flex;

    img {
      max-height: 100px;
      width: auto;
    }
  }

  h2 {
    font-size: 35px;
    color: #344054;
    text-align: center;
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 30px;
    }
  }
`;

export default AboutUs;
