import { useState } from "react";
import { Link } from "react-router-dom";
import AccordionItem from "../../common/AccordionItem";

const Schedule = ({ data, title, company }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className="bg-[#192D46] relative">
      <div className="w-[90%] mx-auto flex gap-12 flex-col-reverse lg:flex-row">
        <div
          className={`w-full hidden lg:flex justify-center pt-16 overflow-hidden ${
            company ? "items-center" : "items-end"
          }`}
        >
          <div className="overflow-hidden flex">
            {data?.map(({ image }, i) => (
              <div
                key={i}
                className="transition-all min-w-full flex justify-center duration-700 w-full"
                style={{ transform: `translate(-${(activeIndex - 1) * 100}%)` }}
              >
                <img src={image} alt="iphone" className={`${company ? "w-full rounded-xl" : "w-fit max-w-[385px]"}`} />
              </div>
            ))}
          </div>
        </div>

        <div className="w-full py-16 space-y-12">
          <h2 className="text-white text-3xl text-center">{title}</h2>

          <div className="text-white space-y-4">
            {data?.map((item, i) => (
              <AccordionItem
                key={i}
                index={i + 1}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                item={item}
              />
            ))}
          </div>

          <div className="flex justify-center">
            <Link to="/quote" className="text-primary underline underline-offset-4 hover:underline text-lg">
              Get a Quote
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
