import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const CTASection = ({ title1, title2, text, btnText, btnLink, image, route }) => {
  return (
    <section className="w-[90%] mx-auto space-y-8 lg:space-y-12 flex flex-col lg:flex-row items-center gap-8 lg:gap-16 xl:gap-20">
      <div className="space-y-6 w-full lg:w-[45%]">
        <h2 className="text-3xl xl:text-4xl">
          {title1} <br className="hidden lg:block" /> {title2}
        </h2>
        <p className="text-lg text-[#808D9E]">{text}</p>

        <div>
          {route ? (
            <Link to={btnLink}>
              <Button type="primary" size="large" style={{ padding: "8px 20px", height: "auto" }}>
                {btnText}
              </Button>
            </Link>
          ) : process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? (
            <a onClick={() => toast.error("Coming soon!")}>
              <Button type="primary" size="large" style={{ padding: "8px 20px", height: "auto" }}>
                {btnText}
              </Button>
            </a>
          ) : (
            <a href={btnLink}>
              <Button type="primary" size="large" style={{ padding: "8px 20px", height: "auto" }}>
                {btnText}
              </Button>
            </a>
          )}
        </div>
      </div>

      <div className="w-full lg:w-[55%]">
        <img src={image} alt={title1} className="w-full" loading="lazy" />
      </div>
    </section>
  );
};

export default CTASection;
