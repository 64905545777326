import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Map, { Marker, Source, Layer } from "react-map-gl";
import { lineString as makeLineString } from "@turf/helpers";
import MapboxDirectionsFactory from "@mapbox/mapbox-sdk/services/directions";
import io from "socket.io-client";
import PubNub from "pubnub";
import { PubNubProvider, usePubNub } from "pubnub-react";
import { reduxFunctions } from "../../helpers/reduxHelper";
import "mapbox-gl/dist/mapbox-gl.css";
import { config } from "../../config";

let locationInterval = null;
let socket = null;
let pubnub = null;

const CustomMap = ({
  width,
  locations,
  ongoing,
  user,
  data,
  getTripToken,
  active_height,
  refreshTripData,
  setStatusUpdate,
}) => {
  const [viewPort, setViewPort] = useState({
    zoom: 12,
    latitude: 6.524379,
    longitude: 3.379206,
  });
  const [route, setRoute] = useState(null);

  const [tripId, setTripId] = useState();
  const [truckLocation, setTruckLocation] = useState({});
  const [truckCoord, setTruckCoord] = useState([]);

  useEffect(() => {
    const newViewPort = { ...viewPort };
    if (locations?.start?.length > 0) {
      newViewPort.latitude = locations.start[1];
      newViewPort.longitude = locations.start[0];

      setViewPort(newViewPort);
    }

    if (ongoing) {
      newViewPort.zoom = 14;

      setViewPort(newViewPort);
    }
    if (ongoing) {
      fetchRoute();
    }
  }, [locations, truckCoord]);

  const accessToken = config.REACT_APP_MAPBOX_TOKEN;
  const directionsClient = MapboxDirectionsFactory({ accessToken });
  const startingPoint = locations?.start;
  const destinationPoint = locations?.end;

  const fetchRoute = async () => {
    const starting = truckCoord?.length > 0 && data._id === truckLocation.trip_id ? truckCoord : startingPoint;
    if (truckCoord?.length > 0 && data._id === truckLocation.trip_id) {
      //   console.log(starting);
    }
    const reqOptions = {
      waypoints: [{ coordinates: starting }, { coordinates: destinationPoint }],
      profile: "driving-traffic",
      geometries: "geojson",
    };
    const res = await directionsClient.getDirections(reqOptions).send();
    // console.log(res?.body?.routes[0]?.geometry?.coordinates);
    const newRoute = makeLineString(res?.body?.routes[0]?.geometry?.coordinates);
    // console.log(newRoute)
    setRoute(newRoute);
  };

  useEffect(() => {
    initPubNub();
    _getTripToken(data?._id);
    connectPubNub();
  }, [data, tripId]);

  const handleMessage = (event) => {
    // console.log("handling message from pubnub");
    // console.log(event);
    try {
      setTruckLocation(event.message.data);
      setStatusUpdate({ status: event.message.data?.status, id: event.message.data?.trip_id });
      setTruckCoord([event.message.data?.longitude, event.message.data?.latitude]);
      // console.log(event.message.data.status, data.state.value)
      if (event.message.data.status !== data.state.value) {
        refreshTripData();
        // console.log('Check difference!!')
      }
    } catch (e) {}
    // return data;
  };

  const _getTripToken = (id) => {
    getTripToken(id)
      .then((res) => {
        // console.log(res);
        setTripId(res.payload.data.token.token);
      })
      .catch((error) => {
        return error;
      });
  };

  const initPubNub = () => {
    // console.log("Initialising pubnub");
    // console.log(user?._id || "demo");
    pubnub = new PubNub({
      subscribeKey: "sub-c-eb01fc04-3e2c-11ec-b886-526a8555c638",
      publishKey: "pub-c-6c22d978-a384-494d-828f-b1a6a1582a59",
      uuid: user?._id || "demo",
    });
  };

  const connectPubNub = () => {
    // console.log("connecting and listening to pubnub");
    // console.log(data?._id);
    pubnub.subscribe({ channels: [data?._id] });
    pubnub.addListener({ message: handleMessage });
  };

  return (
    <div style={{ position: active_height ? "static" : "fixed" }}>
      <Map
        {...viewPort}
        mapboxAccessToken={config.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        style={{ width: width + "vw", height: active_height ? active_height : "90vh" }}
        onDrag={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
        add
        attributionControl={false}
        onZoom={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
      >
        {locations?.start?.length > 0 && (
          <Marker latitude={locations?.start[1]} longitude={locations?.start[0]}>
            <img src={"/starticon.png"} alt={"start-icon"} style={{ width: "70px", height: "70px" }} />
          </Marker>
        )}

        {truckLocation?.latitude && truckLocation.longitude && (
          <Marker
            latitude={truckLocation?.latitude}
            longitude={truckLocation.longitude}
            rotation={truckLocation?.bearing}
            bearing={truckLocation?.bearing}
          >
            <img src={"/truck_map.png"} alt={"moving-icon"} style={{ width: "60px", height: "60px" }} />
          </Marker>
        )}

        {locations?.end?.length > 0 && (
          <Marker latitude={locations?.end[1]} longitude={locations?.end[0]}>
            <img src={"/stopicon.png"} alt={"start-icon"} style={{ width: "70px", height: "70px" }} />
          </Marker>
        )}

        {route && (
          <Source id="my-data" type="geojson" data={route}>
            <Layer
              id="lineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "rgba(1,31,43,0.5)",
                "line-width": 4,
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
};

const mapStateToProps = ({ user, ongoing_trips }) => ({ user, ongoing_trips });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CustomMap);
