import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { reduxFunctions } from "../../helpers/reduxHelper";
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from "react-redux";
import CustomSelect from "../common/Select";

const options = [
  { label: "I own a truck", value: "Truck Owner" },
  { label: "I want to move goods", value: "Goods or Cargo Owner" },
  // { label: "As a Driver", value: "As a Driver" },
];

const PartnerForm = (props) => {
  const [data, setData] = useState({});

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const createLead = async (data) => {
    try {
      const res = await props.createLead(data);
      if (res?.error) {
        throw new Error("Opps, an error occured");
      }
      setData({});
      toast.success("Submitted successfully");
    } catch (e) {
      toast.error("Opps, an error occured");
    }
  };

  const handleSubmit = () => {
    if (!data?.first_name || !data?.last_name || !data?.phone_number || !data?.email || !data?.business_type)
      return toast.error("Please fill all required fields");

    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      company: data?.company_name,
      phone: data?.phone_number,
      email: data?.email,
      business_type: data?.business_type,
      additional_information: data?.info,
      lead_source: "Website/Partner",
    };

    createLead(payload);
  };

  return (
    <>
      <div className="w-[90%] mx-auto mt-8 flex flex-col items-center text-center">
        <h2 className="text-3xl">How to Partner with Go Truck</h2>
        <p className="max-w-[800px] text-[#90999E]">
          To partner with us in other ways, please fill out the form below and submit your information. A member of our
          team will review your submission and reach out to you.
        </p>
      </div>

      <div className="flex justify-center">
        <FormContainer>
          <Form layout={"vertical"} onFinish={() => {}}>
            <Form.Item
              label="First Name"
              required={false}
              rules={[{ required: true, message: "Please input your first name" }]}
              className="input-item"
            >
              <Input name="first_name" value={data.first_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              required={false}
              rules={[{ required: true, message: "Please input your last name" }]}
              className="input-item"
            >
              <Input name="last_name" value={data.last_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Company Name (Optional)"
              required={false}
              rules={[{ required: false, message: "Please input your company name" }]}
              className="input-item"
            >
              <Input name="company_name" value={data.company_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              required={false}
              rules={[
                { required: true, message: "Please input your phone number" },
                {
                  type: "number",
                  message: "Please enter a valid phone number",
                },
              ]}
              className="input-item"
            >
              <Input
                name="phone_number"
                //  type="number"
                value={data.phone_number}
                onChange={handleChange}
                style={{ WebkitAppearance: "none", margin: 0 }}
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Email Address"
              required={false}
              rules={[
                { required: true, message: "Please input your email" },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              className="input-item"
            >
              <Input name="email" value={data.email} onChange={handleChange} className="input" />
            </Form.Item>

            <div className="mb-4">
              <label className="">Do you own a truck or want to move goods?</label>
              <div className="selectContainer2 mt-2">
                <CustomSelect
                  options={options}
                  size="large"
                  handleChange={(value) => setData({ ...data, business_type: value })}
                  value={data.business_type}
                  bordered={false}
                  className="w-full text-xs"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                />
              </div>
            </div>

            <Form.Item label="Additional Information (Optional)" required={false} className="input-item">
              <Input.TextArea
                name="info"
                style={{ minHeight: "100px" }}
                value={data.info}
                onChange={handleChange}
                className="input"
              />
            </Form.Item>

            <div className={"btn-container"}>
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                {props?.loading_lead ? (
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={props?.loading_lead}
                    size={100}
                    width={5}
                    margin={3}
                    height={15}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </FormContainer>
      </div>
    </>
  );
};

const FormContainer = styled.div`
  width: 100%;
  max-width: 700px;
  border: 5px solid #fafafa;
  border-radius: 5px;
  padding: 30px;
  margin: 20px auto 50px auto;

  h4 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;
    font-size: 18px;
  }

  .input {
    height: 40px;
    border-radius: 5px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  button {
    height: 40px;
    border-radius: 5px;
    width: 120px;
    margin: 0 auto;
  }

  .selectContainer2 {
    border: 1px solid #d0d5dd;
    border-radius: 5px;
  }

  @media (max-width: 750px) {
    width: 100%;
    border: none;
  }
`;

const mapStateToProps = ({ loading_lead }) => ({ loading_lead });

const mapDispatchToProps = reduxFunctions;
export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm);
