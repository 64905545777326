import { useEffect, useState } from "react";
import styled from "styled-components";
import CustomMap from "../../components/common/CustomMap";
import ActiveTrip from "../../components/common/ActiveTrip";
import { connect } from "react-redux";
import { Button } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import TripSummaryModal from "../../components/common/TripSummaryModal";
import { Link } from "react-router-dom";
import usePageTitle from "../../components/hooks/usePageTitle";

const OngoingTrips = (props) => {
  const [ongoingTrips, setOngoingTrips] = useState(props.ongoing_trips);
  const [trip, setTrip] = useState({});
  const [tripData, setTripData] = useState({});
  const [locations, setLocations] = useState({ start: [], end: [] });
  const [visible, setVisible] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState({ status: "", id: "" });

  usePageTitle("Ongoing Trips");

  const { status } = statusUpdate;

  const { loading_ongoing_trips } = props;

  const getOngoing = async () => {
    try {
      const res = await props.getOngoingTrips();
      // console.log(res, 'on going trips');
      if (res?.error) {
        throw new Error(res?.error);
      }
      let allTrips = res?.payload?.data?.trips;

      allTrips[0].active = true;
      setTripData(allTrips[0]);

      setLocations({ start: allTrips[0]?.start_location?.coordinates, end: allTrips[0]?.end_location?.coordinates });
      setOngoingTrips(allTrips);
      return;
    } catch (error) {
      // console.log(error, 'error');
      return error;
    }
  };

  useEffect(() => {
    getOngoing();
  }, [status]);

  const handleTripSelect = (trip) => {
    const newTrips = ongoingTrips.map((newTrip) => {
      if (newTrip?._id === trip?._id) {
        newTrip.active = true;
        return newTrip;
      } else {
        newTrip.active = false;
        return newTrip;
      }
    });
    setTripData(trip);
    // console.log(newTrips)
    setOngoingTrips(newTrips);
    setLocations({ start: trip?.start_location?.coordinates, end: trip?.end_location?.coordinates });
  };

  const getTrip = async (id) => {
    setVisible(true);
    try {
      const res = await props.getTrip(id);
      // console.log(res?.payload?.data?.trip)
      setTrip(res?.payload?.data?.trip);

      if (res?.error) {
        throw new Error(res);
      }
    } catch (error) {
      setVisible(false);
      return error;
    }
  };

  const getOngoingRefresh = async () => {
    try {
      const res = await props.getOngoingTrips();
      // console.log(res, 'on going trips');
      if (res?.error) {
        throw new Error(res?.error);
      }
      setOngoingTrips(res?.payload?.data?.trips);
      return;
    } catch (error) {
      // console.log(error, 'error');
      return error;
    }
  };

  return ongoingTrips.length > 0 ? (
    <StyledContainer>
      <Item className={"item1"}>
        <CustomMap
          width={50}
          locations={locations}
          ongoing
          data={tripData}
          refreshTripData={getOngoingRefresh}
          setStatusUpdate={setStatusUpdate}
        />
      </Item>
      <Item>
        {ongoingTrips.map((trip) => (
          <div key={trip?._id} onClick={() => handleTripSelect(trip)}>
            <ActiveTrip
              trip={trip}
              getTrip={getTrip}
              handleTripSelect={handleTripSelect}
              getOngoingRefresh={getOngoingRefresh}
            />
          </div>
        ))}
      </Item>
      <TripSummaryModal
        loading={props.loading_trip}
        visible={visible}
        closeModal={() => setVisible(false)}
        data={trip}
        ongoing={true}
        getOngoing={getOngoing}
      />
    </StyledContainer>
  ) : (
    <Empty>
      <img src={"/new-empty.svg"} alt={"empty trips icon"} />
      <h2>No Ongoing trip</h2>
      <p>You currently do not have an ongoing trip.</p>
      <p>Please create a new trip.</p>
      <Link to="/app/request">
        <Button type={"primary"}>Request Trip</Button>
      </Link>
    </Empty>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #f8f8f8;
  min-height: 90vh;

  @media (max-width: 850px) {
    .item1 {
      display: none;
    }
  }
`;

const Item = styled.div`
  //width: 500px;
  flex: 1;
`;

const Empty = styled.div`
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  p,
  img {
    margin: 0;
  }

  h2 {
    color: #344054;
  }

  button {
    height: 40px;
    width: 200px;
    border-radius: 5px;
    margin-top: 15px;
  }
`;

const mapStateToProps = ({ user, ongoing_trips, loading_trip, loading_ongoing_trips }) => ({
  user,
  ongoing_trips,
  loading_trip,
  loading_ongoing_trips,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(OngoingTrips);
