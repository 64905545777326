import { useEffect, useState } from "react";
import { Badge, Avatar, Image, Button } from "antd";
import styled from "styled-components";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineNotifications } from "react-icons/md";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/go-logo.svg";
import { BiMenuAltRight } from "react-icons/bi";
import { RiArrowDownSLine } from "react-icons/ri";
import { AiOutlineCaretDown } from "react-icons/ai";
import useWindowSize from "../../hooks/useWindowSize";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import NavDropdown from "./NavDropdown";
import TripsDropdown from "./TripsDropdown";
import MobileListItem from "./Home/MobileListItem";

function AppNavbar(props) {
  const [ongoingTrips, setOngoingTrips] = useState(props.ongoing_trips);
  const [userData, setUserData] = useState(props?.user);
  const [dropdown, setDropdown] = useState(false);
  const [tripsDropdown, setTripsDropdown] = useState(false);
  const { width } = useWindowSize();
  const [activeNav, setActiveNav] = useState(false);

  const [isTripsItemOpen, setIsTripsItemOpen] = useState(false);
  const [isProfileItemOpen, setIsProfileItemOpen] = useState(false);

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#e27626" : "#667085",
    };
  };
  const navigate = useNavigate();

  const { loading_trip } = props;

  const getOngoing = async () => {
    try {
      const res = await props.getOngoingTrips();
      // console.log(res, 'on going trips');
      if (res?.error) {
        throw new Error(res?.error);
      }
      setOngoingTrips(res?.payload?.data?.trips);
      return;
    } catch (error) {
      // console.log(error, 'error');
      return error;
    }
  };

  useEffect(getOngoing, [loading_trip]);

  const getUser = async () => {
    try {
      const response = await props.getMe();
      setUserData(response?.payload?.data?.user);
      // console.log(response?.payload?.data?.user);
      if (response?.error) {
        throw new Error(response);
      }
    } catch (e) {
      // console.log(e, 'error');
      return e;
    }
  };

  const { update_user_loading } = props;

  useEffect(getUser, [update_user_loading]);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/login", { replace: true });
  };

  return (
    <Nav>
      <Inner>
        <NavLink to={"/app/request"}>
          <img src={logo} alt={logo} className="max-h-8" />
        </NavLink>
        {width > 880 ? (
          <>
            <List>
              <li>
                <NavLink to={"/app/request"} style={navLinkStyles}>
                  Request Trip
                </NavLink>
              </li>
              <li>
                <Badge count={ongoingTrips.length} color={"#e27626"} showZero>
                  <NavLink to={"/app/ongoing-trips"} style={navLinkStyles}>
                    Ongoing Trips
                  </NavLink>
                </Badge>
              </li>
              <li
                className={"trips-item"}
                onMouseEnter={() => setTripsDropdown(true)}
                onMouseLeave={() => setTripsDropdown(false)}
              >
                <NavLink
                  to={"/app/trips"}
                  style={navLinkStyles}
                  className={"trips"}
                  onClick={(e) => e.preventDefault()}
                >
                  Trips
                  <AiOutlineCaretDown size={13} className={"trips-icon"} />
                </NavLink>
                {tripsDropdown && <TripsDropdown setDropdown={setDropdown} />}
              </li>

              <li>
                <NavLink to={"/app/price-estimator"} style={navLinkStyles}>
                  Price Estimator
                </NavLink>
              </li>
            </List>
            {/*<Icons>*/}
            {/*    <NavLink to={'/app/notification'} style={navLinkStyles}>*/}
            {/*        <MdOutlineNotifications size={25} className={'icon1 icon'}/>*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={'/app/settings'} style={navLinkStyles}>*/}
            {/*        <FaRegUserCircle size={23} className={'icon2 icon'}/>*/}
            {/*    </NavLink>*/}
            {/*</Icons>*/}

            <Right>
              <div className={"notification"}>
                <MdOutlineNotifications size={23} className={"icon"} color="#344054" />
              </div>
              <div
                className={"profile-container"}
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
              >
                <div className={"profile"}>
                  <div className={"profile-inner"}>
                    {userData?.profile_picture?.original ? (
                      <Avatar src={userData?.profile_picture?.original} size={"small"} />
                    ) : (
                      <FaRegUserCircle size={23} className={"icon"} color="#344054" />
                    )}
                    <span>{userData?.given_name ? userData?.given_name : "User"}</span>
                  </div>
                  <AiOutlineCaretDown size={18} color="#344054" />
                </div>
                {dropdown && <NavDropdown setDropdown={setDropdown} userEmail={userData.email} />}
              </div>
            </Right>
          </>
        ) : (
          // <BiMenuAltRight size={40} color="#344054" />}
          <Burger onClick={() => setActiveNav((activeNav) => !activeNav)}>
            <div className={activeNav ? "rotate1 one" : "one"}></div>
            <div className={activeNav ? "rotate2 two" : "two"}></div>
            <div className={activeNav ? "rotate3 three" : "three"}></div>
          </Burger>
        )}
        {
          <div className={activeNav ? "navOpen mobile" : "mobile"}>
            <MobileListItem link={"/app/request"} title={"Request Trip"} setActiveNav={setActiveNav} />
            <MobileListItem
              link={"/app/ongoing-trips"}
              title={"Ongoing Trips"}
              setActiveNav={setActiveNav}
              count={ongoingTrips?.length}
            />
            <MobileListItem
              title={"Trips"}
              setActiveNav={setActiveNav}
              subset={[
                { title: "Upcoming trips", link: "trips/upcoming" },
                { title: "Trip History", link: "trips/history" },
              ]}
              isOpen={isTripsItemOpen}
              setIsOpen={setIsTripsItemOpen}
            />
            <MobileListItem link={"/app/price-estimator"} title={"Price Estimator"} setActiveNav={setActiveNav} />
            <MobileListItem
              image={userData?.profile_picture?.original}
              title={userData?.given_name ? userData?.given_name : "User"}
              setActiveNav={setActiveNav}
              subset={[
                { title: userData?.email ? userData?.email : "User", link: "profile", email: true },
                { title: "Settings", link: "settings" },
              ]}
              isOpen={isProfileItemOpen}
              avatar={true}
              setIsOpen={setIsProfileItemOpen}
            />
            <Button type={"primary"} onClick={handleLogout}>
              Logout
            </Button>
          </div>
        }
      </Inner>
    </Nav>
  );
}

const Nav = styled.nav`
  height: 10vh;
  background: #fafafa;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Inner = styled.nav`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile {
    background: ${({ theme }) => theme.colors.first};
    height: 90vh;
    width: 100%;
    display: block;
    align-items: center;
    position: fixed;
    margin: 0;
    top: 10vh;
    right: 100%;
    transition: transform 0.5s ease;
    overflow: hidden;
    z-index: 10;
    padding: 50px 30px;
  }

  .navOpen {
    transform: translateX(100%);
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 18px;
  }
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;

  li {
    cursor: pointer;
    margin-left: 28px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    height: 10vh;
    display: flex;
    align-items: center;
  }

  .trips {
    display: flex;
    align-items: center;
  }

  .trips-icon {
    margin-left: 5px;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  color: #667085;

  .icon {
    cursor: pointer;
  }

  .icon2 {
    margin-left: 5px;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  .icon {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .notification {
    background: #f5faff;
    border: 1px solid #d9e9fa;
    padding: 5px 10px;
    border-radius: 22px;
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
  }

  .profile-container {
    height: 10vh;
    display: flex;
    align-items: center;
  }

  .profile {
    height: 40px;
    box-sizing: border-box;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5faff;
    border: 1px solid #d9e9fa;
    padding: 5px 10px;
    border-radius: 30px;
    margin-left: 15px;
    cursor: pointer;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    margin-left: 10px;
    font-size: 15px;
    //font-weight: 500;
  }

  .profile-inner {
    display: flex;
    align-items: center;
  }
`;

const Burger = styled.div`
  //position: relative;
  cursor: pointer;

  div {
    background: rgb(28, 28, 28);
    border-radius: 1px;
    display: block;
    transition: all 0.2s ease;
  }
  .one {
    padding: 1.3px 7px;
    width: 60%;
    margin-bottom: 0.3rem;
  }

  .three {
    padding: 1.3px 13px;
    width: 100%;
  }

  .two {
    padding: 1.3px 13px;
    width: 100%;
    margin-bottom: 0.3rem;
  }

  .rotate1 {
    transform: rotateZ(40deg) translateY(400%);
    width: 100%;
  }
  .rotate2 {
    opacity: 0;
  }
  .rotate3 {
    transform: rotateZ(-40deg) translateY(-400%);
  }
`;

const mapStateToProps = ({ user, ongoing_trips, update_user_loading, loading_trip }) => ({
  user,
  ongoing_trips,
  update_user_loading,
  loading_trip,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbar);
