import { useState } from 'react';
import styled, {ThemeProvider} from 'styled-components';
import { connect } from  'react-redux';
import OTPInput from "otp-input-react";
import locked from '../../assets/locked.jpg'
import Modal from 'react-awesome-modal';
import { Inner, OTPContainer} from '../../styles/verification';

import { Button } from "antd";
import {reduxFunctions} from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";


const VerificationModal = (props) => {
    const [otp, setOtp] = useState('');


    const verifyAccount = async () => {
        try{
            const res = await props.verifyUser(otp);

            if(res?.error){
                throw new Error(res?.error?.response?.data?.message)
            }

            toast.success(
                (t) =>
                    <span>User successfully verify!
                    </span>, {});

            props.closeModal();
            props.openVerifiedModal();
            return;
        }catch (e) {
            toast.error(
                (t) =>
                    <span>
                        {e.toString().split(":")[1]
                            ? e.toString().split(":")[1] :
                            "Error verifying user"}
                    </span>, {});
            return e;
        }
    }

    const handleResend = async () => {
        try{
            const res = await props.resendVerificationCode();

            if(res?.error){
                throw new Error(res?.error?.response?.data?.message)
            }

            toast.success(
                (t) =>
                    <span>Code Resent
                    </span>, {});

            return;
        }catch (e) {
            toast.error(
                (t) =>
                    <span>
                        {e.toString().split(":")[1]
                            ? e.toString().split(":")[1] :
                            "Error sending code"}
                    </span>, {});
            return e;
        }
    }





    return (
        <Modal visible={props.visible} width="650" height="700" effect="fadeInUp" onClickAway={() => {}}>
            <Inner>
                <img src={locked} alt={'locked'} />
                <h1>Account Verification</h1>
                <p>We sent a verification code to your email and   <br /> phone number. Enter below.</p>
                <OTPContainer>
                    <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} />
                </OTPContainer>
                <Button type="primary" onClick={verifyAccount}>
                    {props.loading_verify_user ?
                        <ScaleLoader color={"#ffffff"} loading={props.loading_verify_user} size={100} width={8} margin={5} height={30}/>
                    : "Verify"
                }
                </Button>
                <p>Didn't receive the code? <span className={'resend'} onClick={handleResend}>Resend</span></p>
            </Inner>
        </Modal>
    )
}


const mapStateToProps = ({user, loading_verify_user}) =>({user, loading_verify_user});

const mapDispatchToProps = reduxFunctions;



export default  connect(mapStateToProps, mapDispatchToProps)(VerificationModal);