import styled from "styled-components";
import one from "../../../assets/01.svg";
import two from "../../../assets/02.svg";
import three from "../../../assets/03.svg";
import vector1 from "../../../assets/Vector 1.PNG";
import vector2 from "../../../assets/Vector 2.PNG";

const HowItWorks = () => {
  return (
    <StyledSchedule>
      <h2>How does it work? It's simple</h2>
      <Inner>
        <Item>
          <img src={one} alt={"one"} />
          <h3>Create an account</h3>
          <p>Signing up for a free account is easy and straightforward.</p>
        </Item>
        <Image src={vector1} alt={"vector1"} />
        <Item>
          <img src={two} alt={"two"} className={"special-one"} />
          <h3>Request Trip</h3>
          <p>Fill up the necessary details and book a trip in the next minutes.</p>
          <img src={two} alt={"two"} className={"special-two"} />
        </Item>
        <Image src={vector2} alt={"vector2"} />
        <Item>
          <img src={three} alt={"three"} />
          <h3>Get Booked</h3>
          <p>Pick a destination and we handle the rest. Your consignment is in good hands.</p>
        </Item>
      </Inner>
    </StyledSchedule>
  );
};

const StyledSchedule = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2,
  h3 {
    color: ${({ theme }) => theme.colors.secondary};
  }
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 20px;
  }
  p {
    color: dimgray;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textOne};
  }

  @media (max-width: 830px) {
    padding: 50px 0;

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const Inner = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;
const Image = styled.img`
  height: 130px;
  align-self: center;
  padding: 0 -50px;
  //width: 10%;
  //margin-top: 50px;

  @media (max-width: 1000px) {
    height: 150px;
    padding: 0 0px;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

const Item = styled.div`
  .special-one {
    display: none;
  }

  @media (max-width: 500px) {
    .special-one {
      display: block;
      margin: 0 auto;
    }
    .special-two {
      display: none;
    }
  }
`;

export default HowItWorks;
