import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Divider, Form, Input, Radio } from "antd";
import { useState } from "react";
import PrimaryCard from "../../components/layout/PrimaryCard";
import SecondaryCard from "../../components/layout/SecondaryCard";
import InputAddress from "../../components/common/AddressInput";
import EstimatorModal from "../../components/common/EstimatorModal";
import { reduxFunctions } from "../../helpers/reduxHelper";
import ScaleLoader from "react-spinners/ScaleLoader";
import Footer from "../../components/layout/Footer";
import { MAX_TONNAGE, TRIP_TYPE, truckOptions } from "../../constants/trip";
import CustomSelect from "../../components/common/Select";
import SelectTerminal from "../../components/common/SelectTerminal";
import TerminalList from "../../components/TerminalList";
import ReactSnackBar from "react-js-snackbar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import usePageTitle from "../../components/hooks/usePageTitle";

const PriceEstimator = (props) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    start_address: "",
    end_address: "",
    tonnage: undefined,
    offloading_duration: undefined,
    cargo_type: "CONTAINER",
    trip_category: "TERMINAL_LOCATION",
    item_size: undefined,
    is_container_owner: false,
    drop_off_address: "" || "Drop off not specified",
    start_lng: null,
    start_lat: null,
    end_lng: null,
    end_lat: null,
    drop_off_lng: null,
    drop_off_lat: null,
  });
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [startCoordinates, setStartCoordinates] = useState({ lat: null, lng: null });
  const [endCoordinates, setEndCoordinates] = useState({ lat: null, lng: null });
  const [dropOffCoordinates, setDropOffCoordinates] = useState({ lat: null, lng: null });
  const [tripDetails, setTripDetails] = useState({});
  const [visibleTerminalList, setVisibleTerminalList] = useState(false);
  const [terminalOpener, setTerminalOpener] = useState("");
  const [notificationState, setNotificationState] = useState({ show: false, showing: false, message: "" });

  usePageTitle("Price Estimator");

  const show = (message) => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true, message });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 3500);
  };

  const handleCargoType = ({ target }) => {
    const newData = { ...data };
    newData.cargo_type = target.value;
    setData(newData);
  };
  const handleCargoTypeSelect = (value) => {
    const newData = { ...data };
    newData.cargo_type = value;
    setData(newData);
  };

  const handleTripType = ({ target }) => {
    const newData = { ...data };
    newData.trip_category = target.value;
    setData(newData);
  };

  const handleTripTypeSelect = (value) => {
    const newData = { ...data };
    newData.trip_category = value;
    setData(newData);
  };

  const handleSelectChange = (value) => {
    const newData = { ...data };
    newData.item_size = value;
    setData(newData);
  };

  const handleOwnerChange = ({ target }) => {
    const newData = { ...data };
    newData.is_container_owner = target.value;
    setData(newData);
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const MAXIMUM_TONNAGE_VALUE = MAX_TONNAGE(data?.cargo_type);

  const containerOptions = truckOptions.filter((el) => el.container);

  const options = data?.cargo_type === "CONTAINER" ? containerOptions : truckOptions;

  const handleFinish = async () => {
    if (!data.item_size) return show("Please select Truck Type");
    if (!startAddress) return show("Please enter Pickup location");
    if (!endAddress) return show("Please enter Dropoff location");
    if (!data.tonnage) return show("Please enter a valid Tonnage");
    if (data.tonnage < 0) return show("Tonnage should be a positive integer");
    if (data.tonnage > MAXIMUM_TONNAGE_VALUE) return show("Tonnage limit has been exceeded");
    if (!data.offloading_duration) return show("Please enter Offloading Duration");
    if (!data.is_container_owner && data?.cargo_type == "CONTAINER" && !dropOffAddress)
      return show("Please enter Empties Address");

    const tripData = {
      cargo_type: data?.cargo_type,
      start_location: [startCoordinates.lng, startCoordinates.lat],
      end_location: [endCoordinates.lng, endCoordinates.lat],
      item_size: data.item_size,
      trip_category: data.cargo_type === "CONTAINER" ? data.trip_category : TRIP_TYPE.LOCATION_LOCATION,
      offloading_duration: 86400000 * +data.offloading_duration,
      is_container_owner: data.is_container_owner,
      tdo_valid_until: moment().add(3, "days"),
      start_address: startAddress,
      end_address: endAddress,
      drop_off_address: dropOffAddress,
      tonnage: data.tonnage,
    };

    try {
      const res = await props.createCost(tripData);
      // console.log(res, 'response');
      if (res?.error) {
        throw new Error(res?.error);
      }
      setTripDetails({
        ...tripData,
        price: res?.payload?.data?.cost?.price,
        price_upper_bound: res?.payload?.data?.cost?.price_upper_bound,
      });
      return setVisible(true);
    } catch (error) {
      // console.log(error, 'error');
      return error;
    }
  };

  return (
    <>
      <div className="max-w-[1200px] mx-auto">
        <StyledEstimator>
          <h1>Price Estimator</h1>
          <Divider />

          <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
            {notificationState.message}
          </ReactSnackBar>

          <Form layout={"vertical"} onFinish={handleFinish}>
            <h2 className={"sub-title"}>Cargo Type</h2>
            <FieldContainer>
              <div className={"card-container"} onClick={() => handleCargoTypeSelect("CONTAINER")}>
                <PrimaryCard image="/icon-3.png" title="Container" info="Includes contained goods in a container">
                  <Radio
                    checked={data?.cargo_type == "CONTAINER"}
                    onChange={handleCargoType}
                    value={20}
                    name="cargo_type"
                  />
                </PrimaryCard>
              </div>
              <div className={"card-container"} onClick={() => handleCargoTypeSelect("LOOSE_GOODS")}>
                <PrimaryCard
                  image={"/icon-2.png"}
                  title="Loose Goods"
                  info="Includes uncontained goods like, bags, berets, crates etc"
                >
                  <Radio
                    checked={data?.cargo_type == "LOOSE_GOODS"}
                    onChange={handleCargoType}
                    value={40}
                    name={"forty"}
                  />
                </PrimaryCard>
              </div>
            </FieldContainer>

            {data.cargo_type === "CONTAINER" && (
              <>
                <h2 className={"sub-title"}>Trip Type</h2>
                <TripType>
                  <div
                    className={"type-card-container"}
                    onClick={() => handleTripTypeSelect(TRIP_TYPE.TERMINAL_LOCATION)}
                  >
                    <PrimaryCard
                      image={"/icon-1.png"}
                      title={"Terminal - Location"}
                      info={"Move cargo from terminal to a location"}
                    >
                      <Radio
                        checked={data.trip_category == TRIP_TYPE.TERMINAL_LOCATION}
                        value={TRIP_TYPE.TERMINAL_LOCATION}
                        name={TRIP_TYPE.TERMINAL_LOCATION}
                        onChange={handleTripType}
                      />
                    </PrimaryCard>
                  </div>
                  <div
                    className={"type-card-container"}
                    onClick={() => handleTripTypeSelect(TRIP_TYPE.LOCATION_LOCATION)}
                  >
                    <PrimaryCard
                      image={"/icon-1.png"}
                      title={"Location - Location"}
                      info={"Move cargo from location to location"}
                    >
                      <Radio
                        checked={data.trip_category == TRIP_TYPE.LOCATION_LOCATION}
                        value={TRIP_TYPE.LOCATION_LOCATION}
                        name={TRIP_TYPE.LOCATION_LOCATION}
                        onChange={handleTripType}
                      />
                    </PrimaryCard>
                  </div>
                </TripType>
              </>
            )}

            <h2 className={"sub-title"}>Truck Type</h2>
            <Form.Item className="sub-title">
              <div className="selectContainer2 mb-10">
                <CustomSelect
                  options={options}
                  size="large"
                  handleChange={handleSelectChange}
                  value={data.item_size}
                  bordered={false}
                />
              </div>
            </Form.Item>

            {data?.cargo_type == "CONTAINER" && (
              <>
                <h2 className={"sub-title"}>Container Ownership</h2>
                <FieldContainer>
                  <div className={"card-container"}>
                    <SecondaryCard title={"Rented"} info={"Choose if you rented the cargo."}>
                      <Radio
                        className={"radio}"}
                        checked={!data.is_container_owner}
                        value={false}
                        name={"rented"}
                        onChange={handleOwnerChange}
                      />
                    </SecondaryCard>
                  </div>
                  <div className={"card-container"}>
                    <SecondaryCard title={"Owner"} info={"Choose if you own the cargo"}>
                      <Radio
                        className={"radio"}
                        checked={data.is_container_owner}
                        value={true}
                        name={"owner"}
                        onChange={handleOwnerChange}
                      />
                    </SecondaryCard>
                  </div>
                </FieldContainer>
              </>
            )}

            <Form.Item label="Pick up location" name="start_address" required={false} className="input-item">
              <InputAddress
                placeholder="Enter your pick up location"
                address={startAddress}
                setAddress={setStartAddress}
                coordinates={startCoordinates}
                setCoordinates={setStartCoordinates}
              />
              {data.trip_category == "TERMINAL_LOCATION" && (
                <SelectTerminal
                  setVisibleTerminalList={setVisibleTerminalList}
                  opener={"start"}
                  setTerminalOpener={setTerminalOpener}
                />
              )}
            </Form.Item>
            <Form.Item label="Drop Off location" name="end_address" required={false} className="input-item">
              <InputAddress
                placeholder="Enter your drop off location"
                address={endAddress}
                setAddress={setEndAddress}
                coordinates={endCoordinates}
                setCoordinates={setEndCoordinates}
              />
            </Form.Item>

            <Form.Item label="Tonnage" type={"number"} name="tonnage" required={false} className="input-item">
              <Input
                name="tonnage"
                value={data.tonnage}
                type={"number"}
                min={0}
                max={MAXIMUM_TONNAGE_VALUE}
                onChange={handleChange}
                size={"large"}
                placeholder={`Enter the tonnage (max ${MAXIMUM_TONNAGE_VALUE}Tonnes)`}
              />
            </Form.Item>

            <Form.Item
              label="Offloading duration"
              type={"number"}
              name="offloading_duration"
              required={false}
              className="input-item"
            >
              <Input
                name="offloading_duration"
                value={data.offloading_duration}
                min={0}
                onChange={handleChange}
                type={"number"}
                size={"large"}
                placeholder="Days required to offload."
              />
            </Form.Item>

            {!data.is_container_owner && data?.cargo_type == "CONTAINER" && (
              <Form.Item
                label="Empties Drop off Address"
                name="drop_off_address"
                required={false}
                className="input-item"
              >
                <InputAddress
                  placeholder="Enter your drop off location"
                  address={dropOffAddress}
                  setAddress={setDropOffAddress}
                  coordinates={dropOffCoordinates}
                  setCoordinates={setDropOffCoordinates}
                />
              </Form.Item>
            )}

            <div className={"btn-container"}>
              <Button type={"primary"} htmlType={"submit"}>
                {props.create_cost_loading ? (
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={props.create_cost_loading}
                    size={150}
                    width={8}
                    margin={5}
                    height={30}
                  />
                ) : (
                  "Get Estimate"
                )}
              </Button>
            </div>
          </Form>
          <EstimatorModal visible={visible} closeModal={() => setVisible(false)} data={tripDetails} />
        </StyledEstimator>

        <TerminalList
          visible={visibleTerminalList}
          closeModal={() => setVisibleTerminalList(false)}
          setVisibleTerminalList={setVisibleTerminalList}
          setAddress={terminalOpener === "start" ? setStartAddress : setEndAddress}
          setCoordinates={terminalOpener === "start" ? setStartCoordinates : setEndCoordinates}
        />
      </div>
      <Footer width={"90%"} />
    </>
  );
};

const StyledEstimator = styled.div`
  width: 90%;
  padding: 70px 0;
  margin: 0 auto;

  form {
    width: 90%;
    margin: 0 auto;
  }

  .selectContainer2 {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  label {
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  input {
    border-radius: 5px;
    height: 50px;
    margin-bottom: 15px;
  }

  .sub-title {
    margin-bottom: 15px;
  }

  .type-card-container {
    margin-bottom: 30px;
  }

  .btn-container {
    margin-top: 50px;
    text-align: center;
  }

  button {
    font-weight: bold;
    font-size: 15px;
    width: 200px;
    height: 60px;
    border-radius: 5px;
  }
`;

const TripType = styled.div`
  margin-bottom: 50px;
`;

const FieldContainer = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 50px;

  .radio {
    width: 20px;
  }

  .card-container {
    width: 100%;
    height: auto;
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    .card-container {
      width: 48%;
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    display: block;

    .card-container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const mapStateToProps = ({ user, create_cost_loading }) => ({ user, create_cost_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PriceEstimator);
