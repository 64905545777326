import styled from "styled-components";
import FeatureCard from "../../components/common/FeatureCard";

const PartnerCTA = () => {
  const data = [
    {
      title: "Reliability",
      text: "We know that timely delivery is critical to your business, which is why we're committed to on-time deliveries and efficient transportation solutions.",
    },
    {
      title: "Scalability",
      text: "As your business grows, so will your transportation needs. We have the resources and capacity to scale our services to meet your evolving requirements.",
    },
    {
      title: "Transparency",
      text: "We believe in open communication and transparency, which is why we provide real-time tracking and regular updates on your shipments.",
    },
  ];

  return (
    <>
      <Inner>
        <Schedule>
          <div className="w-[90%] mx-auto space-y-4 pt-14 flex flex-col items-center text-center">
            <h2 className="text-3xl">Partner with Go Truck</h2>
            <p className="max-w-[800px]">
              Our team is dedicated to delivering exceptional service and creating value for our partners. We understand
              that every business has unique transportation needs, which is why we offer flexible solutions tailored to
              your specific requirements. When you partner with Go Truck, you'll enjoy:
            </p>
          </div>
          <CardContainer>
            {data?.map(({ title, text }) => (
              <Item key={title}>
                <FeatureCard center icon="/icons/partner-icon.svg" title={title} info={text} />
              </Item>
            ))}
          </CardContainer>
        </Schedule>
      </Inner>
    </>
  );
};

const Inner = styled.div`
  p {
    color: #888888;
  }
`;

const Schedule = styled.div`
  width: 100%;
  padding: 25px 0;
  background: rgb(255, 245, 238);
  background: linear-gradient(180deg, rgba(255, 245, 238, 1) 69%, rgba(255, 255, 255, 1) 70%);

  h2 {
    font-size: 40px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  p {
    font-size: 15px;
  }
  .p2 {
    margin-top: -15px;
  }

  .head {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 13px;
    }
  }
`;

const CardContainer = styled.div`
  width: 90%;
  margin: 80px auto 40px;
  display: flex;

  @media (max-width: 830px) {
    display: block;
  }
`;

const Item = styled.div`
  width: 30%;
  margin: 0 auto;
  display: flex;

  p {
    font-size: 13px;
  }

  @media (max-width: 830px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export default PartnerCTA;
