import React, { useRef, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import Move from "../../components/layout/Home/Move";
import PartnerCTA from "../../components/partner/PartnerCTA";
import PartnerForm from "../../components/partner/PartnerForm";
import { useState } from "react";
import usePageTitle from "../../components/hooks/usePageTitle";

const PartnerWithUs = () => {
  const [pageReady, setPageReady] = useState(false);
  const scrollRef = useRef(null);

  usePageTitle("Partner With Us");

  useEffect(() => {
    setPageReady(true);
  }, []);

  useEffect(() => {
    if (pageReady) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [pageReady]);

  return (
    <>
      <PartnerCTA />
      <div ref={scrollRef} />
      <PartnerForm />

      <Move width={"100%"} />
      <Footer width={"90%"} />
    </>
  );
};

export default PartnerWithUs;
