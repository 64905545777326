import styled from 'styled-components';


const PrimaryCard = ({title, info, value, children, image}) => {
    return (
        <StyledCard>
            <Head>
                <div className={'title'}>
                    <img src={image} alt={'icon'}/>
                    <h2>{title}</h2>
                </div>
                {children}
            </Head>
            <p>
                {info}
            </p>
        </StyledCard>
    )
}


const StyledCard = styled.div`
    border: 1px solid rgb(231, 231, 231);
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
  
    img{
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

  p{
    padding-left: 60px;
    font-size: 16px;
    margin: 0;
    color: #667085;
  }
  
  :hover{
    background: #fff3e9;
    border: 1px solid  #fff3e9;
  }
  
  @media (max-width: 300px){
    h2{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
  }
`

const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  
    .title{
      display: flex;
      align-items: center;
    }
  
  h2{
    margin: 0;
    color: #667085;
  }
  
`

export default PrimaryCard;