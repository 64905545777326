import styled from "styled-components";

export const FormContainer = styled.div`
  background: white;
  min-height: 80vh;
  padding: 40px 25px;
  border: 1px solid #d4d3d3;
  border-radius: 5px;
  
  form{
    margin-top: 50px;
  }
  
  .input-item{
    margin-bottom: 30px;
  }
  
  .input{
    height: 50px;
    border-radius: 7px;
  }
  
  .remember{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  a{
    color: black;
  }
  
  button{
    width: 100%;
    border-radius: 5px;
    margin: 30px 0;
    height: 50px;
    font-weight: 500;
    font-size: 20px;
  }
  
  .action{
    text-align: center;
    font-size: 20px;
    a{
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary}
    }
  }
`