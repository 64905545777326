import styled from "styled-components";

const SecondaryButton = (props) => {
  return (
    <Button href={props.link} target="_blank" rel="noreferrer">
      {props.children}
      <Text>
        <p>
          {props.info} <br /> <span>{props.text}</span>
        </p>
      </Text>
    </Button>
  );
};

const Button = styled.a`
  width: 180px;
  height: 70px;
  display: flex;
  color: black;
  background-color: #efefef;
  align-items: center;
  justify-content: center;
  text-align: left;
  //padding: 5px 20px;
  border-radius: 5px;
  border: none;
  margin: 0 5px;

  @media (max-width: 830px) {
    padding: 5px 10px;
  }
`;
const Text = styled.div`
  margin-left: 10px;
  p {
    font-size: 10px;
    margin: 0;
  }
  span {
    font-size: 15px;
    font-weight: bold;
  }
`;

export default SecondaryButton;
