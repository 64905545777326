import React, { useEffect } from "react";

const BillingCard = ({ detail, setDefaultBank, onDelete, handleRadio }) => {
  useEffect(() => {
    if (detail?.active) {
      setDefaultBank(detail?._id);
    }
  }, [detail]);

  const styles = {
    container: {
      background: detail?.active ? "#FFF2E9" : "white",
    },
    innerContainer: { display: "flex", gap: "10px" },
  };

  //   {
  //     padding: 15px;
  //     border-radius: 8px;
  //     border: 1px solid #E4E7EC;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: start;
  // }

  return (
    <div
      className={`p-3 md:p-4 rounded-lg border ${detail?.active ? "bg-[#FFF2E9]" : "bg-[#fff]"}`}
      style={{ border: "1px solid #E4E7EC" }}
    >
      <div className="flex gap-4 items-start">
        <img src="/card.png" className="max-w-[50px] -mt-1" />

        <div className="space-y-2">
          <p className="text-[#667085] text-base m-0">Card ending with {detail?.last_four_digits}</p>

          {detail?.active && <p className="text-[#58B689] m-0">Primary Card</p>}
          {!detail?.active && (
            <div className="flex gap-6">
              <p
                className="text-[#E27626] cursor-pointer m-0"
                onClick={() => {
                  setDefaultBank(detail?._id);
                  handleRadio(detail?._id);
                }}
              >
                Set as primary
              </p>
              <p
                className="text-[#667085] cursor-pointer m-0"
                onClick={(e) => {
                  onDelete(detail?._id);
                }}
              >
                Delete
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingCard;
