import { useState } from "react";
import styled from "styled-components";
import Footer from "../../components/layout/Footer";
import { Divider, Form, Input, Button } from "antd";
import Group from "../../assets//Group.svg";
import { HiOutlinePhone } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import usePageTitle from "../../components/hooks/usePageTitle";

const Contact = () => {
  const [data, setData] = useState({});
  usePageTitle("Contact Us");

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <>
      <Inner>
        <Content>
          <Info>
            <h2>Contact Our team</h2>
            <p className="mb-10 text-[#90999E] text-base">
              If you have any questions or need assistance, you can reach us by phone, email, or send a message. Let us
              know how we can help you today!
            </p>
            {/* <Divider />
            <div className={"list-container"}>
              <h3>With GoTruck you can</h3>
              <div className={"list"}>
                <div className={"item"}>
                  <img src={Group} alt={"offer-item"} />
                  <p>It is a long established fact that a reader</p>
                </div>
                <div className={"item"}>
                  <img src={Group} alt={"offer-item"} />
                  <p>It is a long established fact that a reader</p>
                </div>
                <div className={"item"}>
                  <img src={Group} alt={"offer-item"} />
                  <p>It is a long established fact that a reader</p>
                </div>
              </div>
            </div>
            <Divider /> */}
            <div>
              <div className={"phone"}>
                <HiOutlinePhone className={"icon"} />
                <div className={"contact-text"}>
                  <h4>Phone Number</h4>
                  <h4 className={"contact-h4 text-sm"}>+234 810 144 7225</h4>
                </div>
              </div>
              <div className={"address"}>
                <IoLocationOutline className={"icon"} />
                <div className={"contact-text"}>
                  <h4>Address</h4>
                  <h4 className={"contact-h4 text-sm"}>
                    Plot 1 Towry Close, Off Idejo Street, Off Adeola Odeku Street, Victoria Island, Lagos, Nigeria.
                  </h4>
                </div>
              </div>
            </div>
          </Info>
          <FormContainer>
            <h4>Send us a message</h4>
            <Form layout={"vertical"} onFinish={() => {}}>
              <Form.Item
                label="Full name"
                required={false}
                rules={[{ required: true, message: "Please input your full name!" }]}
                className="input-item"
              >
                <Input
                  name="full_name"
                  value={data.full_name}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter your Full name"
                />
              </Form.Item>
              <Form.Item
                label="Email"
                required={false}
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                ]}
                className="input-item"
              >
                <Input
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter your email"
                />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                required={false}
                rules={[{ required: true, message: "Please input your phone number!" }]}
                className="input-item"
              >
                <Input
                  name="phone_number"
                  value={data.phone_number}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter your phone number"
                />
              </Form.Item>

              <Form.Item
                label="Message"
                required={false}
                rules={[{ required: true, message: "Please input your message!" }]}
                className="input-item"
              >
                <Input.TextArea
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                  className="input"
                  style={{ minHeight: "100px" }}
                  placeholder="Enter your message"
                />
              </Form.Item>
              <div className={"btn-container"}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </FormContainer>
        </Content>
      </Inner>
      <Footer width={"90%"} />
    </>
  );
};

const Inner = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  min-height: 60vh;
  justify-content: space-between;
  padding: 50px 0;

  @media (max-width: 750px) {
    display: block;
  }
`;

const Info = styled.div`
  width: 40%;

  .ant-divider {
    background: #d4d3d3;
  }

  h2 {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  h3 {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .list-container {
    padding: 30px 0;
  }

  .item {
    display: flex;
    align-items: center;
    margin: 0 auto 20px;

    img {
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }

  .phone,
  .address {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    h4 {
      margin: 0;
      font-size: 17px;
      color: ${({ theme }) => theme.colors.secondary};
    }

    .contact-h4 {
      margin-top: 8px;
      color: #90999e;
      font-weight: 400;
    }

    .contact-text {
      width: 80%;
    }

    .icon {
      // width: 20%;
      margin-right: 30px;
      font-size: 25px;
      text-align: center;
    }
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  @media (max-width: 300px) {
    h2 {
      font-size: 25px;
    }
  }
`;

const FormContainer = styled.div`
  width: 55%;
  border: 5px solid #fafafa;
  border-radius: 5px;
  padding: 50px 40px;

  h4 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;
    font-size: 18px;
  }

  .input {
    height: 40px;
    border-radius: 5px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  button {
    height: 40px;
    border-radius: 5px;
    width: 120px;
    margin: 0 auto;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export default Contact;
