import styled from "styled-components";
import { BsChevronRight } from 'react-icons/bs'


const SelectTerminal = ({ setVisibleTerminalList, opener, setTerminalOpener }) => {

    const handleSelect = () => {
        setTerminalOpener(opener)
        setVisibleTerminalList(true)
    }

    return (
        <Container onClick={handleSelect}>
            <div className={'left'}>
                <img src={'/icon6.png'} alt={'logo'} />
                <div className={'text-content'}>
                    <p className={'p1'}>Terminals</p>
                    <p className={'p2'}>{`Click to choose terminal as your ${opener === 'start' ? 'pick up' : 'drop off'} location.`}</p>
                </div>
            </div>
            <div>
                <BsChevronRight />
            </div>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 300;
    }

    .p1 {
      font-weight: 500;
      margin-bottom: -3px;
    }
  }

  &:hover {
    background: #f3f3f3;
  }
  
  @media (max-width: 535px){
    p{
      font-size: 10px;
    }
  }
`

export default SelectTerminal;