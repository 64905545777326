import styled from "styled-components";
import ReactSnackBar from "react-js-snackbar";
import { Form, Input, Radio, Button } from "antd";
import PrimaryCard from "../../layout/PrimaryCard";
import { useState } from "react";
import SecondaryCard from "../../layout/SecondaryCard";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MAX_TONNAGE, truckOptions } from "../../../constants/trip";
import CustomSelect from "../Select";

const ContainerDetailsForm = ({ data, handleChange, setData, setCurrentStep }) => {
  const [notificationState, setNotificationState] = useState({ show: false, showing: false, message: "" });

  const show = (message) => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true, message });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 3500);
  };

  const handleContainerType = ({ target }) => {
    const newData = { ...data };
    newData.cargo_type = target.value;
    setData(newData);
  };

  const handleContainerTypeSelect = (value) => {
    const newData = { ...data };
    newData.cargo_type = value;
    setData(newData);
  };

  const handleOwnerChange = ({ target }) => {
    const newData = { ...data };
    newData.is_container_owner = target.value;
    setData(newData);
  };

  const MAXIMUM_TONNAGE_VALUE = MAX_TONNAGE(data?.cargo_type);

  const handleContinue = () => {
    if (!data.item_size) return show("Please select Truck Type");
    if (!data.tonnage) return show("Please enter a valid Tonnage");
    if (data.tonnage < 0) return show("Tonnage should be a positive integer");
    if (data.tonnage > MAXIMUM_TONNAGE_VALUE) return show("Tonnage limit has been exceeded");
    if (!data.offloading_duration) return show("Please enter Offloading Duration");
    setCurrentStep(1);
  };

  const handleSelectChange = (value) => {
    const newData = { ...data };
    newData.item_size = value;
    setData(newData);
  };

  const containerOptions = truckOptions.filter((el) => el.container);

  const options = data?.cargo_type === "CONTAINER" ? containerOptions : truckOptions;

  return (
    <StyledForm layout={"vertical"}>
      <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
        {notificationState.message}
      </ReactSnackBar>

      <h2 className={"sub-title"}>Cargo Type</h2>
      <FieldContainer>
        <div className={"card-container"} onClick={() => handleContainerTypeSelect("CONTAINER")}>
          <PrimaryCard image={"/icon-3.png"} title={"Container"} info={"Includes contained goods in a container"}>
            <Radio
              checked={data?.cargo_type === "CONTAINER"}
              onChange={handleContainerType}
              value={"CONTAINER"}
              name={"CONTAINER"}
            />
          </PrimaryCard>
        </div>
        <div className={"card-container"} onClick={() => handleContainerTypeSelect("LOOSE_GOODS")}>
          <PrimaryCard
            image={"/icon-2.png"}
            title={"Loose Goods"}
            info={"Includes uncontained goods like, bags, berets, crates etc"}
          >
            <Radio
              checked={data?.cargo_type === "LOOSE_GOODS"}
              onChange={handleContainerType}
              value={"LOOSE_GOODS"}
              name={"LOOSE_GOODS"}
            />
          </PrimaryCard>
        </div>
      </FieldContainer>

      <Form.Item label="Truck Type" className="sub-title">
        <div className="selectContainer2">
          <CustomSelect
            options={options}
            size="large"
            handleChange={handleSelectChange}
            value={data.item_size}
            bordered={false}
          />
        </div>
      </Form.Item>

      {data?.cargo_type === "CONTAINER" && <h2 className={"sub-title"}>Container Ownership</h2>}
      {data?.cargo_type === "CONTAINER" && (
        <FieldContainer>
          <div className={"card-container"}>
            <SecondaryCard title={"Rented"} info={"Choose if you rented the cargo."}>
              <Radio
                className={"radio}"}
                checked={!data.is_container_owner}
                value={false}
                name={"rented"}
                onChange={handleOwnerChange}
              />
            </SecondaryCard>
          </div>
          <div className={"card-container"}>
            <SecondaryCard title={"Owner"} info={"Choose if you own the cargo"}>
              <Radio
                className={"radio"}
                checked={data.is_container_owner}
                value={true}
                name={"owner"}
                onChange={handleOwnerChange}
              />
            </SecondaryCard>
          </div>
        </FieldContainer>
      )}

      <Form.Item label="Tonnage" name="tonnage" required={false} className="input-item">
        <div>
          <Input
            name="tonnage"
            type="number"
            min={0}
            max={MAXIMUM_TONNAGE_VALUE}
            value={data.tonnage}
            onChange={handleChange}
            size={"large"}
            placeholder={`Enter the tonnage (max ${MAXIMUM_TONNAGE_VALUE}Tonnes)`}
          />
        </div>
      </Form.Item>

      <Form.Item
        type={"number"}
        label="Offloading Duration"
        name="offloading_duration"
        required={false}
        className="input-item"
      >
        <div>
          <Input
            type={"number"}
            name="offloading_duration"
            value={data.offloading_duration}
            min={0}
            onChange={handleChange}
            size={"large"}
            placeholder="Days required to offload"
          />
        </div>
      </Form.Item>

      <div className={"btn-container"}>
        <Button type={"primary"} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  input {
    border-radius: 5px;
    height: 50px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
  }

  .sub-title {
    color: #344054;
    font-weight: 600;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.secondary};
    color: #344054;
  }

  .selectContainer2 {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    width: 100%;
    margin-right: 20px;
    z-index: 200000;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;

  .radio {
    width: 20px;
  }

  .card-container {
    width: 45%;
    //margin-right: 30px;
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    .card-container {
      width: 48%;
      margin-right: 0;
    }
  }

  @media (max-width: 800px) {
    display: block;

    .card-container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export default ContainerDetailsForm;
