import styled from "styled-components";


export const ContentContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  
  
  .btn-container-2{
    display: none;
  }

  button{
    border-radius: 5px;
    width: 100px;
    height: 50px;
    padding: 0;
  }
  
  .btn{
    margin-left: 20px;
  }

  .top-btn{
    width: 150px;
  }


  @media (max-width: 600px){
    button{
      width: 48%
    }

    .btn{
      margin-left: 0;
    }
    
    .btn-container-2{
      display: flex;
      justify-content: space-between;
    }
    .btn-container{
      display: none;
    }
  }
`

export const Head = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    h2{
      margin: 0;
    }
`