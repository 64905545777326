import styled from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "react-awesome-modal";
import { Button } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import SlAgreement from "../../screens/Auth/SlAgreement";
import { agreementData } from "../../helpers/slaHelper";

const LicenseModal = (props) => {
  const updateProfile = async () => {
    const newData = { ...props.user };
    newData.is_license_agreed_to = true;

    try {
      const response = await props.updateUser(newData);
      if (response?.error) {
        throw new Error(response?.error?.response?.data?.message);
      }
      toast.success((t) => <span>Successfully accepted License.</span>, {});
      props.setVisibleLicense(false);
      return;
    } catch (e) {
      toast.error(
        (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error accepting license"}</span>,
        {}
      );
      return e;
    }
  };

  return (
    <Modal visible={props.visible} width="800" height="90%" effect="fadeInUp" onClickAway={() => {}}>
      <Inner>
        <h1>License and Agreement</h1>
        <Content>
          {agreementData?.map(({ title, content }, i) => (
            <div className={"detail"} key={title + i}>
              <p className={"left"}>
                {i + 1}. {title}
              </p>
              <div className={"right"}>
                {content?.map((text, i) => (
                  <p key={i}>{text}</p>
                ))}
              </div>
            </div>
          ))}
          <div className={"detail"}>
            <p className={"left"}>{agreementData?.length + 1}. Contact Information</p>
            <div className={"right"}>
              <p>
                If you have any questions or concerns regarding this SLA, please contact us at support@gotruck.io or
                write us at
              </p>

              <div className="flex flex-col text-[#667085] mb-8">
                <span>Plot 1 Towry Close</span>
                <span>Off Idejo Street</span>
                <span>Off Adeola Odeku Street</span>
                <span>Victoria Island, Lagos</span>
                <span>Nigeria</span>
                <span>admin@gotruck.io</span>
              </div>

              <p>
                This SLA represents a good faith effort by GoTruck Nigeria LTD to provide a high level of service to our
                trucking company partners.
              </p>
            </div>
          </div>
        </Content>
        <Button type={"primary"} onClick={updateProfile}>
          {props.update_user_loading ? (
            <ScaleLoader
              color={"#ffffff"}
              loading={props.update_user_loading}
              size={150}
              width={8}
              margin={3}
              height={30}
            />
          ) : (
            "I Accept"
          )}
        </Button>
      </Inner>
    </Modal>
  );
};

export const Inner = styled.div`
  overflow: scroll;
  height: 90vh;
  padding: 40px 60px;
  margin: 0 auto;

  h1 {
    font-weight: bold;
  }

  p {
    margin: 0;
    padding: 0;
    //font-weight: 600;
    font-size: 16px;
    color: #9ea5b0;
  }

  .mb {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    border-radius: 5px;
    height: 50px;
    //font-weight: 600;
    font-size: 18px;
    margin-top: 30px;
  }

  @media (max-width: 900px) {
    padding: 20px 30px;
    }
  }
`;

const Content = styled.div`
  p,
  ul {
    margin: 0;
    font-size: 17px;
    color: #667085;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .left {
      // width: 20%;
      font-size: 18px;
      font-weight: 500;
      color: #192d46;
      min-width: fit;
    }
    .right {
      // width: 75%;

      p {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    .sub-title {
      font-weight: 600;
      color: #192d46;
    }
  }

  @media (max-width: 900px) {
    .detail {
      display: block;

      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
`;

const mapStateToProps = ({ user, update_user_loading }) => ({ user, update_user_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(LicenseModal);
