import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaGooglePlay } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import dotted4 from "../../../assets/dotted4.svg";
import dotted5 from "../../../assets/dotted5.svg";
import SecondaryButton from "../../common/SecondaryButton";

const Move = ({ width }) => {
  const navigate = useNavigate();

  return (
    <StyledMove>
      <Inner width={width}>
        <img src={dotted4} alt={"dotted"} className={"image-one"} />
        <Content>
          <div className="title">
            <h2>Ready to move?</h2>
            <h3>Register and get started with GoTruck</h3>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-col gap-4 sm:flex-row">
              <SecondaryButton
                text={"Google Play"}
                info={"Get it on"}
                link="https://play.google.com/store/apps/details?id=com.gotruck.user"
              >
                <FaGooglePlay size={27} />
              </SecondaryButton>
              <SecondaryButton text={"App Store"} info={"Download on the"} link="#">
                <BsApple size={27} />
              </SecondaryButton>
            </div>
          </div>
          {/* <h3>
            Or create account to{" "}
            <span className={"get-started"} onClick={() => navigate("/signup")}>
              get started
            </span>
          </h3> */}
        </Content>
        <img src={dotted5} alt={"dotted"} className={"image-two"} />
      </Inner>
    </StyledMove>
  );
};

const StyledMove = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const Inner = styled.div`
  background: #192d46;
  color: white;
  width: ${({ width }) => width};
  height: 375px;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  justify-content: space-between;

  .get-started {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  .image-one {
    align-self: flex-end;
    height: 105px;
    width: 105px;
  }

  .image-two {
    align-self: flex-start;
    height: 105px;
    width: 105px;
  }

  h2,
  h3 {
    color: white;
    margin-top: 5px;
  }

  h2 {
    font-size: 40px;
    margin-bottom: 0px;
  }
  h3 {
    margin: 20px 0;
  }

  @media (max-width: 830px) {
    .image-one {
      display: none;
    }

    .image-two {
      display: none;
    }

    h2 {
      font-size: 30px;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  align-self: center;
  text-align: center;

  h2,
  h3 {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 32px;
  }

  .title {
    margin-bottom: 24px;
  }
`;

export default Move;
