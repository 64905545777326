import styled from "styled-components";
// import Modal from "react-awesome-modal";
import { Button, Checkbox, Modal } from "antd";
import React, { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const RequirementFormModal = ({ visible, closeModal, setVisible, setVisible2, handleCreateTrip, loading }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCancel = () => {
    setVisible2(false);
    setVisible(true);
  };

  const createTrip = () => {
    if (isChecked) {
      handleCreateTrip();
    } else {
      // console.log('You need to accept the agreement!!')
    }
  };

  return (
    <CustomStyledModal
      visible={visible}
      width={400}
      height={250}
      centered
      onCancel={closeModal}
      footer={""}
      closable={false}
      maskClosable={false}
    >
      <Inner>
        <h1>Requirement Form</h1>

        <Checkbox onChange={({ target }) => setIsChecked(target.checked)} checked={isChecked}>
          <p>
            I acknowledge that I have read, understood, and agree to the{" "}
            <a href="/sla" target="_blank" rel="noreferrer">
              Service Level Agreement
            </a>
            .
          </p>
        </Checkbox>

        <Agree>
          <div>
            <Button className={"btn1"} onClick={handleCancel} size={"large"}>
              Cancel
            </Button>
            <Button type={"primary"} size={"large"} disabled={!isChecked} onClick={createTrip}>
              {loading ? (
                <ScaleLoader color={"#ffffff"} loading={loading} size={100} width={5} margin={3} height={15} />
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </Agree>
      </Inner>
    </CustomStyledModal>
  );
};

const Inner = styled.div`
  padding: 30px 20px;
  background: white;
  z-index: -100;

  h1 {
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 16px;
  }
`;

const Agree = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;

  button {
    border-radius: 5px;
  }

  .btn1 {
    margin-right: 10px;
  }
`;

const CustomStyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 2px;
  }
`;

export default RequirementFormModal;
