import styled from "styled-components";
import { useState, useEffect } from "react";

import Modal from "react-awesome-modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Button, Form, Input, Image, Upload, message } from "antd";
import { connect } from "react-redux";
import ImageUpload from "./ImageUpload";
import toast from "react-hot-toast";
import { reduxFunctions } from "../../helpers/reduxHelper";
import BounceLoader from "react-spinners/BounceLoader";

const ProfileInfoModal = (props) => {
  const [user, setUser] = useState({});

  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { data } = props;

  useEffect(() => {
    setUser(data);
  }, [data]);

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...user };
    newData[input.name] = input.value;
    setUser(newData);
  };

  const updateProfile = async () => {
    const newData = { ...user };
    if (imageUrl) {
      newData.profile_picture = {
        original: imageUrl,
      };
    }
    try {
      const response = await props.updateUser(newData);
      // console.log(response);
      if (response?.error) {
        throw new Error(response?.error?.response?.data?.message);
      }
      toast.success((t) => <span>Successfully updated profile</span>, {});
      props.profileNext();
      return;
    } catch (e) {
      toast.error(
        (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error updating your profile"}</span>,
        {}
      );
      return e;
    }
  };

  return (
    <Modal visible={props.visible} width="650" height="90%" effect="fadeInUp" onClickAway={() => {}}>
      <Inner>
        <h1>Profile Information</h1>
        <Form layout={"vertical"} onFinish={updateProfile}>
          <div className={"image-container"}>
            {loadingImage ? (
              <div className={"image-loading"}>
                <BounceLoader size={60} loading={loadingImage} color={"#e27626"} />
              </div>
            ) : (
              <Image
                src={imageUrl ? imageUrl : data?.profile_picture?.original ? data?.profile_picture?.original : "error"}
                fallback={"/empty.png"}
              />
            )}
            {/*<Upload {...props}>Upload Profile Picture</Upload>*/}
            <ImageUpload
              data={user}
              loadingImage={loadingImage}
              uploadUrl={uploadUrl}
              fileList={fileList}
              imageUrl={imageUrl}
              setUploadUrl={setUploadUrl}
              sets3ImageUrl={sets3ImageUrl}
              setImageUrl={setImageUrl}
              setFileList={setFileList}
              setLoadingImage={setLoadingImage}
              s3ImageUrl={s3ImageUrl}
              listType="picture-card"
              button={false}
            />
          </div>
          <Form.Item label="First name" name="given_name" required={false} className="input-item">
            <div>
              <Input
                name="given_name"
                value={user.given_name}
                onChange={handleChange}
                className="input"
                placeholder="Enter your First name"
              />
            </div>
          </Form.Item>
          <Form.Item label="Last name" name="family_name" required={false} className="input-item">
            <div>
              <Input
                name="family_name"
                value={user.family_name}
                onChange={handleChange}
                className="input"
                placeholder="Enter your Last name"
              />
            </div>
          </Form.Item>

          <Form.Item label="Phone Number" name="phone_number" required={false} className="input-item">
            <div>
              <Input
                name="phone_number"
                value={user.phone_number}
                onChange={handleChange}
                className="input"
                placeholder="Enter your phone number"
              />
            </div>
          </Form.Item>
          <div className={"btn-container"}>
            {props.cardsLength < 0 && <Button onClick={props.handleBack}>Back</Button>}
            <Button type="primary" htmlType="submit">
              {props.update_user_loading ? (
                <ScaleLoader
                  color={"#ffffff"}
                  loading={props.update_user_loading}
                  size={150}
                  width={8}
                  margin={3}
                  height={30}
                />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </Form>
      </Inner>
    </Modal>
  );
};

export const Inner = styled.div`
  overflow: auto;
  height: 90vh;
  padding: 20px 60px;
  margin: 0 auto;

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .image-loading {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dedede;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .ant-image-img,
  .ant-image {
    width: 180px;
    height: 180px;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .ant-upload {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    cursor: pointer;
  }

  label {
    font-weight: 500;
  }

  h1 {
    text-align: center;
    font-weight: 500;
  }

  p {
    font-weight: 600;
    font-size: 20px;
    color: #9ea5b0;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    width: 30%;
    border-radius: 5px;
    height: 50px;
    //font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
    padding: 0;
  }

  .resend {
    color: ${({ theme }) => theme.colors.primary};
  }

  input {
    padding: 5px 10px;
    height: 50px;
    border-radius: 5px;
  }

  @media (max-width: 500px) {
    button {
      width: 40%;
    }
  }
`;

const mapStateToProps = ({ user, update_user_loading }) => ({ user, update_user_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoModal);
