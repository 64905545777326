import { useState } from 'react';
import styled, {ThemeProvider} from 'styled-components';
import OTPInput from "otp-input-react";
import unlocked from '../../assets/unlocked.jpg'
import Modal from 'react-awesome-modal';
import { Inner } from '../../styles/verification'
import {Button, Form} from "antd";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";


const ResetPasswordLinkSent = ({ visible, closeModal }) => {
    return (
        <Modal visible={visible} width="650" height="650" effect="fadeInUp" onClickAway={() => {}}>
            <ContentInner>
                <img src={unlocked} alt={'unlocked'} />
                <h1>Reset Link has been sent</h1>
                <p>We have sent you an email with instruction on how  <br /> to reset your password</p>
                <Link to ='/login' className={'back-btn'}>
                    <IoArrowBack className={'icon'}/>
                    <span>Back to sign in</span>
                </Link>
            </ContentInner>
        </Modal>
    )
}

const ContentInner = styled(Inner)`
  
  .back-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  .icon{
    margin-right: 10px;
  }`



export default  ResetPasswordLinkSent;