import {
  signUp,
  loginUser,
  changePassword,
  getMe,
  updateUser,
  getUploadUrl,
  requestPasswordResetEmail,
  resetPassword,
  createCost,
  getPastTrips,
  getTrip,
  createTrip,
  trackTrip,
  getOngoingTrips,
  getUpcomingTrips,
  updateTripStatus,
  getTripToken,
  initiatePayment,
  createCard,
  getCardList,
  deleteCard,
  setActiveCard,
  otpValidation,
  verifyUser,
  resendVerificationCode,
  fetchTerminals,
  createLead,
} from "../redux/actions/mainActions";

export const reduxFunctions = {
  signUp,
  loginUser,
  changePassword,
  getMe,
  updateUser,
  getUploadUrl,
  requestPasswordResetEmail,
  resetPassword,
  createCost,
  getPastTrips,
  getTrip,
  createTrip,
  trackTrip,
  getOngoingTrips,
  getUpcomingTrips,
  updateTripStatus,
  getTripToken,
  initiatePayment,
  createCard,
  getCardList,
  deleteCard,
  setActiveCard,
  otpValidation,
  verifyUser,
  resendVerificationCode,
  fetchTerminals,
  createLead,
};
