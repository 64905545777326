import styled from 'styled-components';
// import master from '../../../../assets/master.png'


const SelectCard = ({ title, image, children, card, handleDelete }) => {
    return (
        <StyledCard>
            <div className={'left'}>
                <img src={image} alt={'master'} />
                <div className={'info'}>
                    <p>{title}</p>
                    <p className={'delete'} onClick={() => handleDelete(card._id)}>delete</p>
                </div>
            </div>
            {children}
        </StyledCard>
    )
}

const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: flex-start;
  border: 1px solid rgb(211, 210, 210);
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 15px;

  img{
    width: 50px;
    object-fit: contain;
    margin-right: 10px;
    margin-top: -5px;
  }

  .info{
    font-size: 16px;
    margin: 0;
    color: #667085;
  }
  
  p{
    margin: 0;
  }
  
  .delete{
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 10px;
    
  }

  :hover{
    background: #fff3e9;
    border: 1px solid  #fff3e9;
  }
  
  .left{
    display: flex;
    align-items: flex-start;
  }
`

export default SelectCard;