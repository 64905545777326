import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../components/layout/Navbar";

const AuthHome = () => {
  const location = useLocation();

  const transparentNavs = ["/", "/company"];

  return (
    <>
      {!transparentNavs?.includes(location.pathname) && <Navbar />}
      <Outlet />
    </>
  );
};

export default AuthHome;
