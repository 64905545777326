import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Divider, Form, Image, Input } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import { ContentContainer, Head } from "../../../styles/settings";
import ImageUpload from "../../../components/common/ImageUpload";
import ScaleLoader from "react-spinners/ScaleLoader";
import BounceLoader from "react-spinners/BounceLoader";
import usePageTitle from "../../../components/hooks/usePageTitle";

const Profile = (props) => {
  const [data, setData] = useState(props.user);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  usePageTitle("Profile");

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const getUser = async () => {
    try {
      const response = await props.getMe();
      setData(response?.payload?.data?.user);
      // console.log(response?.payload?.data?.user);
      if (response?.error) {
        throw new Error(response);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(getUser, []);

  useEffect(() => {
    if (data.profile_picture) {
      setImageUrl(data.profile_picture.original);
      setFileList([
        {
          uid: "current",
          status: "done",
          url: data.profile_picture.original,
        },
      ]);
    }
  }, []);

  const handleSave = async () => {
    const newData = { ...data };
    if (imageUrl) {
      newData.profile_picture = {
        original: imageUrl,
      };
    }
    try {
      const response = await props.updateUser(newData);
      // console.log(response);
      if (response?.error) {
        throw new Error(response?.error?.response?.data?.message);
      }
      toast.success(
        (t) => (
          <span>
            Successfully updated profile
            {/*<button onClick={() => toast.dismiss(t.id)}>Dismiss</button>*/}
          </span>
        ),
        {}
      );
      return;
    } catch (e) {
      toast.error(
        (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error updating your profile"}</span>,
        {}
      );
      return e;
    }
  };

  return (
    <StyledProfile>
      <Head>
        <div>
          <h2>Personal Info</h2>
          <p>Update your personal details</p>
        </div>
        {/*<div className={'btn-container'}>*/}
        {/*    /!*<Button type={''} size={"large"}>cancel</Button>*!/*/}
        {/*    <Button onClick={handleSave} type={'primary'} size={"large"} className={'top-btn'}>*/}
        {/*        {props.update_user_loading ?*/}
        {/*            <ScaleLoader color={"#ffffff"} loading={props.update_user_loading} size={100} width={5} margin={3} height={25}/>*/}
        {/*            : "Save"*/}
        {/*        }*/}
        {/*    </Button>*/}
        {/*</div>*/}
      </Head>
      <Divider />
      <Form layout={"horizontal"}>
        <div className={"profile-picture"}>
          {loadingImage ? (
            <div className={"image-loading"}>
              <BounceLoader size={60} loading={loadingImage} color={"#e27626"} />
            </div>
          ) : (
            <Image
              src={imageUrl ? imageUrl : data?.profile_picture?.original ? data?.profile_picture?.original : "error"}
              fallback={"/empty.png"}
            />
          )}
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            uploadUrl={uploadUrl}
            fileList={fileList}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            listType="picture-card"
            button={false}
          />
        </div>
        <Divider />

        <Form.Item label="First Name" name="given_name" required={false} className="input-item">
          <div>
            <Input
              name="given_name"
              size={"large"}
              value={data?.given_name}
              onChange={handleChange}
              className="input"
              placeholder=""
            />
          </div>
        </Form.Item>
        <Divider />

        <Form.Item label="Last Name" name="family_name" required={false} className="input-item">
          <div>
            <Input
              name="family_name"
              size={"large"}
              value={data?.family_name}
              onChange={handleChange}
              className="input"
              placeholder=""
            />
          </div>
        </Form.Item>
        <Divider />

        <Form.Item label="Phone Number" name="phone_number" required={false} className="input-item">
          <div>
            <Input
              name="phone_number"
              size={"large"}
              value={data?.phone_number}
              onChange={handleChange}
              className="input"
              placeholder="Enter your phone number"
            />
          </div>
        </Form.Item>
        <div className={"save-container"}>
          <Button onClick={handleSave} type={"primary"} size={"large"}>
            {props.update_user_loading ? (
              <ScaleLoader
                color={"#ffffff"}
                loading={props.update_user_loading}
                size={100}
                width={5}
                margin={3}
                height={25}
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>
    </StyledProfile>
  );
};

const StyledProfile = styled(ContentContainer)`
  padding: 40px 0;

  .input-item {
    margin-bottom: 30px;
    padding: 20px 0;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    //margin-left: 10%;
  }

  .input {
    width: 70%;
    //margin-left: 10%;
    border-radius: 5px;
    height: 50px;
  }

  label {
    width: 300px;
    font-size: 17px;
  }

  .profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-loading {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dedede;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .ant-image-img,
  .ant-image {
    width: 180px;
    height: 180px;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .ant-upload {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    cursor: pointer;
  }

  button {
    border-radius: 5px;
    width: 150px;
    height: 50px;
    padding: 0;
  }

  .save-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 750px) {
    label {
      width: 200px;
    }
  }

  @media (max-width: 900px) {
    .input-container,
    .input {
      width: 100%;
    }
  }

  @media (max-width: 570px) {
    .input-container {
      display: block;
    }
  }
`;

const mapStateToProps = ({ user, update_user_loading }) => ({ user, update_user_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
