import { useState, useEffect } from "react";
import moment from "moment";
// const validate = require('validate.js');
import toast from "react-hot-toast";
import Modal from "react-awesome-modal";
import { Button, Divider, Form, Input } from "antd";
import styled, { ThemeProvider } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { creditCardConstraints } from "../../helpers/ValidationHelper";
import CreditCard from "../../models/CreditCard";
import { reduxFunctions } from "../../helpers/reduxHelper";
import ScaleLoader from "react-spinners/ScaleLoader";

const CardModal = (props) => {
  const [card, setCard] = useState(new CreditCard());
  const [data, setData] = useState({
    card_name: "",
    card_email: "",
    cvv: "",
    card_number: "",
    expiry_date: moment(new Date()).format("yyyy-MM"),
    pin: "",
    otp: "",
    requested_pin: "",
  });
  const [loading, setLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [showPin, setShowPin] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const { visible } = props;

  useEffect(() => {
    setData({
      expiry_date: moment(new Date()).format("yyyy-MM"),
    });
  }, [visible]);

  // console.log(moment(new Date()).format('yyyy-MM'));

  useEffect(() => {
    if (data.expiry_date) {
      let month = data?.expiry_date?.split("-")[1];
      let year = data?.expiry_date?.split("-")[0];

      setCard((card) => {
        return {
          ...card,
          expiry_month: month,
          expiry_year: year,
          number: data.card_number,
          cvv: data.cvv,
          email: data.card_email,
          pin: data.pin,
        };
      });
    }
  }, [data]);

  const handleAddCard = async () => {
    setLoading(true);

    let month = data?.expiry_date?.split("-")[1];
    let year = data?.expiry_date?.split("-")[0];

    const theCharge = {
      amount: "5000",
      card: {
        cvv: data.cvv,
        email: data.card_email,
        expiry_month: month,
        expiry_year: year,
        number: data.card_number,
      },
      email: data.card_email,
      pin: data.pin,
    };

    await props
      .initiatePayment(theCharge)
      .then((res) => {
        setLoading(true);
        console.log(res, "resss");
        if (res?.error) {
          throw new Error(res?.error?.response?.data?.message);
        }
        if (res?.payload?.data?.data?.status == "success") {
          setCard((card) => {
            return {
              ...card,
              card_token: res?.payload?.data?.data?.authorization,
              txnRef: res?.payload?.data?.data?.reference,
            };
          });

          props
            .createCard(res?.payload?.data?.data?.reference)
            .then(() => {
              setLoading(false);

              if (res?.error) {
                throw new Error(res?.error?.response?.data?.message);
              }

              if (props?.register) {
                props.handleCard();
              } else {
                props?.closeModal();
              }

              return toast.success((t) => <span>Card successfully added.</span>, {});
            })
            .catch((e) => {
              if (!props?.register) {
                props?.closeModal();
              }
              setLoading(false);
              return toast.error(
                (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
                {}
              );
            });
        } else if (res?.payload?.data?.data?.status == "send_otp") {
          setLoading(false);
          setShowOtp(true);
        } else if (res?.payload?.data?.data?.status == "send_pin") {
          setLoading(false);
          setShowPin(true);
        }
      })
      .catch((e) => {
        if (!props?.register) {
          props?.closeModal();
        }
        setLoading(false);
        return toast.error(
          (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
          {}
        );
      });
  };

  //--------------------------------------------------------------------

  const submitPIN = async () => {
    setLoading(true);
    // console.log("card state");
    let month = data?.expiry_date?.split("-")[1];
    let year = data?.expiry_date?.split("-")[0];

    const theCharge = {
      card: {
        expiry_month: month,
        expiry_year: year,
        number: data.card_number,
        cvv: data.cvv,
        email: data.card_email,
        pin: data.pin,
      },
      txnRef: card?.txnRef,
      email: data.card_email,
      amount: "5000",
      pin: data?.requested_pin,
    };

    await props
      .initiatePayment(theCharge)
      .then((res) => {
        setLoading(false);

        if (res?.error) {
          throw new Error(res?.error?.response?.data?.message);
        }

        if (res?.payload?.data?.data?.status == "success") {
          // console.log("Success PIN First");
          setCard((card) => {
            return {
              ...card,
              card_token: res?.payload?.data?.data?.authorization,
              txnRef: res?.payload?.data?.data?.reference,
            };
          });

          props
            .createCard(res?.payload?.data?.data?.reference)
            .then(() => {
              // console.log('Success PIN Second');
              setLoading(false);

              if (props?.register) {
                props.handleCard();
              } else {
                props?.closeModal();
              }

              if (res?.error) {
                throw new Error(res?.error?.response?.data?.message);
              }
              setShowPin(false);
              return toast.success((t) => <span>Card successfully added.</span>, {});
            })
            .catch((e) => {
              setShowPin(false);
              if (!props?.register) {
                props?.closeModal();
              }
              setLoading(false);
              return toast.error(
                (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
                {}
              );
            });
        } else if (res?.payload?.data?.data?.status == "send_otp") {
          setShowOtp(true);
        }
      })
      .catch((e) => {
        setShowPin(false);
        if (!props?.register) {
          props?.closeModal();
        }
        setLoading(false);
        return toast.error(
          (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
          {}
        );
      });
  };

  //--------------------------------------------------------------------------------

  const submitOTP = async () => {
    setLoading(true);

    await props
      .otpValidation({ ...card, otp: data?.otp, txnRef: props?.validation_info?.reference })
      .then(async (res) => {
        // console.log(res);
        // console.log("Success OTP First");
        setCard((card) => {
          return { ...card, card_token: res?.payload?.data?.data?.authorization };
        });
        if (res?.error) {
          throw new Error(res?.error?.response?.data?.message);
        }

        // console.log(res);

        await props
          .createCard(res?.payload?.data?.data?.reference)
          .then((res) => {
            // console.log('Success OTP Second');
            setLoading(false);

            if (props?.register) {
              props.handleCard();
            } else {
              props?.closeModal();
            }

            if (res?.error) {
              throw new Error(res?.error?.response?.data?.message);
            }

            setShowOtp(false);
            setShowPin(false);
            props.addCardSuccess();
            return toast.success((t) => <span>Card successfully added.</span>, {});
          })
          .catch((e) => {
            setShowOtp(false);
            setShowPin(false);

            if (!props?.register) {
              props?.closeModal();
            }

            setLoading(false);
            return toast.error(
              (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
              {}
            );
          });
      })
      .catch((e) => {
        setShowOtp(false);
        setShowPin(false);

        if (!props?.register) {
          props?.closeModal();
        }

        setLoading(false);
        return toast.error(
          (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error adding card!"}</span>,
          {}
        );
      });
  };

  const handleBack = () => {
    props.closeModal();
    setData({});
  };

  return (
    <Modal visible={props?.visible} width="750" height="600px" effect="fadeInUp">
      {showOtp && (
        <Inner>
          <h1>Input Addition Information</h1>
          <Divider />

          <Form layout={"vertical"}>
            <Form.Item
              label="Received OTP"
              name="otp"
              required={false}
              // className="input-item first"
            >
              <Input name="otp" value={data.otp} onChange={handleChange} className="input" placeholder="OTP" />
            </Form.Item>
            <div className={"btn-contain"}>
              <Button className={"back"} onClick={props?.register ? props?.skipModal : handleBack}>
                {props?.register ? "Skip" : "Back"}
              </Button>
              <Button type={"primary"} onClick={submitOTP}>
                {loading ? (
                  <ScaleLoader color={"#ffffff"} loading={loading} size={100} width={8} margin={5} height={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </Inner>
      )}

      {showPin && (
        <Inner>
          <h1>Input Addition Information</h1>
          <Divider />

          <Form layout={"vertical"}>
            <Form.Item
              label="Card Pin"
              name="requested_pin"
              required={false}
              // className="input-item first"
            >
              <Input
                name="requested_pin"
                value={data.requested_pin}
                onChange={handleChange}
                className="input"
                placeholder="Card Pin"
              />
            </Form.Item>
            <div className={"btn-contain"}>
              <Button className={"back"} onClick={props?.register ? props?.skipModal : handleBack}>
                {props?.register ? "Skip" : "Back"}
              </Button>
              <Button type={"primary"} onClick={submitPIN}>
                {loading ? (
                  <ScaleLoader color={"#ffffff"} loading={loading} size={100} width={8} margin={5} height={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </Inner>
      )}

      {!showOtp && !showPin && (
        <Inner>
          {props?.register && (
            <div className={"close-container"}>
              <AiOutlineClose size={20} onClick={handleBack} className={"icon"} />
            </div>
          )}

          <h1>Add New Card</h1>
          <Divider />
          <Form layout={"vertical"}>
            <div className={"row"}>
              <Form.Item label="Card Number" name="card_number" required={false} className="input-item first">
                <div>
                  <Input
                    name="card_number"
                    value={data.card_number}
                    onChange={handleChange}
                    className="input"
                    placeholder="Card Number"
                  />
                </div>
              </Form.Item>
              <Form.Item label="Expiry Date" name="expiry_date" required={false} className="input-item second">
                <div>
                  <Input
                    name="expiry_date"
                    value={data.expiry_date}
                    onChange={handleChange}
                    className="input"
                    placeholder={"MM/YY"}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    type={"month"}
                  />
                </div>
              </Form.Item>
            </div>
            <div className={"row"}>
              <Form.Item label="Card Name" name="card_name" required={false} className="input-item first">
                <div>
                  <Input
                    name="card_name"
                    value={data.card_name}
                    onChange={handleChange}
                    className="input"
                    placeholder="Name on card"
                  />
                </div>
              </Form.Item>
              <Form.Item label="CVV" name="cvv" required={false} className="input-item second">
                <div>
                  <Input name="cvv" value={data.cvv} onChange={handleChange} className="input" placeholder="cvv" />
                </div>
              </Form.Item>
            </div>

            <div className={"row"}>
              <Form.Item label="Email" name="card_email" required={false} className="input-item first">
                <div>
                  <Input
                    name="card_email"
                    value={data.card_email}
                    onChange={handleChange}
                    className="input"
                    placeholder="Email Address"
                  />
                </div>
              </Form.Item>
              <Form.Item label="Pin" name="pin" required={false} className="input-item second">
                <div>
                  <Input name="pin" value={data.pin} onChange={handleChange} className="input" placeholder="pin" />
                </div>
              </Form.Item>
            </div>

            <div className={"btn-contain"}>
              <Button className={"back"} onClick={props?.register ? props?.skipModal : handleBack}>
                {props?.register ? "Skip" : "Back"}
              </Button>
              <Button type={"primary"} onClick={handleAddCard}>
                {loading ? (
                  <ScaleLoader color={"#ffffff"} loading={loading} size={70} width={8} margin={5} height={20} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Form>
        </Inner>
      )}
    </Modal>
  );
};

const Inner = styled.div`
  padding: 30px 30px;
  height: 100%;
  overflow: scroll;
  //background: white;

  form {
    margin-top: 50px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  label {
    font-size: 17px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }

  .input {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #cacaca;
  }

  .date-picker {
    padding-left: 5px;
    color: #cacaca;
  }

  .first {
    width: 70%;
  }

  .second {
    width: 25%;
  }

  .btn-contain {
    display: flex;
    justify-content: space-between;
  }

  button {
    font-weight: bold;
    height: 50px;
    width: 150px;
    border-radius: 5px;
  }

  .close-container {
    display: flex;
    justify-content: flex-end;
  }

  .icon {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    .row {
      display: block;
    }
    .first,
    .second {
      width: 100%;
    }
  }
`;

const mapStateToProps = ({ user, loading_pay, validation_info }) => ({ user, loading_pay, validation_info });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CardModal);
