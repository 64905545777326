import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_FAIL,
  SIGNUP_USER_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  GET_UPLOAD,
  GET_UPLOAD_FAIL,
  GET_UPLOAD_SUCCESS,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CREATE_COST,
  CREATE_COST_FAIL,
  CREATE_COST_SUCCESS,
  GET_PAST_TRIPS,
  GET_PAST_TRIPS_FAIL,
  GET_PAST_TRIPS_SUCCESS,
  GET_TRIP,
  GET_TRIP_FAIL,
  GET_TRIP_SUCCESS,
  CREATE_TRIP,
  CREATE_TRIP_FAIL,
  CREATE_TRIP_SUCCESS,
  TRACK_TRIP,
  TRACK_TRIP_SUCCESS,
  TRACK_TRIP_FAIL,
  GET_ONGOING_TRIPS,
  GET_ONGOING_TRIPS_FAIL,
  GET_ONGOING_TRIPS_SUCCESS,
  GET_UPCOMING_TRIPS,
  GET_UPCOMING_TRIPS_FAIL,
  GET_UPCOMING_TRIPS_SUCCESS,
  UPDATE_TRIP_STATUS,
  UPDATE_TRIP_STATUS_SUCCESS,
  UPDATE_TRIP_STATUS_FAIL,
  GET_TRIP_TOKEN,
  GET_TRIP_TOKEN_SUCCESS,
  GET_TRIP_TOKEN_FAIL,
  INITIATE_PAYMENT,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAIL,
  CREATE_CARD,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  GET_CARDS,
  GET_CARDS_SUCCESS,
  GET_CARDS_FAIL,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAIL,
  SET_PRIMARY_CARD,
  SET_PRIMARY_CARD_SUCCESS,
  SET_PRIMARY_CARD_FAIL,
  VALIDATE_ACCOUNT,
  VALIDATE_ACCOUNT_SUCCESS,
  VALIDATE_ACCOUNT_FAIL,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  RESEND_CODE,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAIL,
  FETCH_TERMINALS,
  FETCH_TERMINALS_SUCCESS,
  FETCH_TERMINALS_FAIL,
  CREATE_PARTNER,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAIL,
} from "../constants";

import { TRIP_STATE } from "../../constants/trip";
import { config } from "../../config";

export function signUp(user) {
  return {
    types: [SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `users`,
        data: user,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function loginUser(email, password) {
  return {
    types: [LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `oauth`,
        data: {
          email: email,
          password: password,
        },
      },
    },
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `users/me/change_password`,
        data: { oldPassword, newPassword },
      },
    },
  };
}

export function getMe() {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `users/me`,
      },
    },
  };
}

export function updateUser(user) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: `users/me`,
        data: user,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function getUploadUrl(contentType) {
  return {
    types: [GET_UPLOAD, GET_UPLOAD_SUCCESS, GET_UPLOAD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `upload_url`,
        data: {
          content_type: contentType,
        },
      },
    },
  };
}

export function requestPasswordResetEmail(email) {
  return {
    types: [PASSWORD_RESET_EMAIL, PASSWORD_RESET_EMAIL_SUCCESS, PASSWORD_RESET_EMAIL_FAIL],
    payload: {
      request: {
        url: `users/forgot_password`,
        method: "POST",
        data: {
          email: email,
        },
      },
    },
  };
}

export function resetPassword(password, token) {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    payload: {
      request: {
        url: `users/password_reset`,
        method: "POST",
        data: {
          password: password,
          token: token,
        },
      },
    },
  };
}

export function createCost(data) {
  return {
    types: [CREATE_COST, CREATE_COST_SUCCESS, CREATE_COST_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "costs",
        data: data,
      },
    },
  };
}

export function getPastTrips(page = 0) {
  return {
    types: [GET_PAST_TRIPS, GET_PAST_TRIPS_SUCCESS, GET_PAST_TRIPS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `users/me/trips?populate=driver vehicle cost_id&state=${TRIP_STATE.CANCELLED},${TRIP_STATE.FINISHED}&page= ${page}`,
      },
      data: {
        page: page,
      },
    },
  };
}

export function getTrip(id) {
  return {
    types: [GET_TRIP, GET_TRIP_SUCCESS, GET_TRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `trips/${id}`,
        data: {},
      },
    },
  };
}

export function trackTrip(id) {
  return {
    types: [TRACK_TRIP, TRACK_TRIP_SUCCESS, TRACK_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `trackers/track`,
        data: { tracking_id: id },
        baseURL: config.REACT_APP_TRACKING_BASEURL,
      },
    },
  };
}

export function createTrip(data) {
  return {
    types: [CREATE_TRIP, CREATE_TRIP_SUCCESS, CREATE_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "trips",
        data: data,
      },
    },
  };
}

export function getOngoingTrips() {
  return {
    types: [GET_ONGOING_TRIPS, GET_ONGOING_TRIPS_SUCCESS, GET_ONGOING_TRIPS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "users/me/trips/ongoing",
      },
    },
  };
}

export function getUpcomingTrips(page = 0) {
  return {
    types: [GET_UPCOMING_TRIPS, GET_UPCOMING_TRIPS_SUCCESS, GET_UPCOMING_TRIPS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `users/me/trips?populate=driver vehicle cost_id&sort=start_time&state=${TRIP_STATE.ACCEPTED},${TRIP_STATE.PENDING}&page=${page}`,
      },
    },
  };
}

export function updateTripStatus(id, status) {
  return {
    types: [UPDATE_TRIP_STATUS, UPDATE_TRIP_STATUS_SUCCESS, UPDATE_TRIP_STATUS_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `trips/${id}/update_status`,
        data: { status: status },
      },
    },
  };
}

export function getTripToken(tripId) {
  return {
    types: [GET_TRIP_TOKEN, GET_TRIP_TOKEN_SUCCESS, GET_TRIP_TOKEN_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `trips/${tripId}/tracking_permission`,
        data: {
          trip_id: tripId,
        },
      },
    },
  };
}

export function initiatePayment(charge) {
  return {
    types: [INITIATE_PAYMENT, INITIATE_PAYMENT_SUCCESS, INITIATE_PAYMENT_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "charge",
        baseURL: config.REACT_APP_PAYSTACK_URL,
        headers: {
          Authorization: config.REACT_APP_PAYSTACK_SECRET_KEY,
          "Content-Type": "application/json",
        },
        data: charge,
      },
    },
  };
}

export function createCard(reference) {
  return {
    types: [CREATE_CARD, CREATE_CARD_SUCCESS, CREATE_CARD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "cards",
        data: {
          reference_id: reference,
        },
      },
    },
  };
}

export function getCardList() {
  return {
    types: [GET_CARDS, GET_CARDS_SUCCESS, GET_CARDS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "users/me/cards",
      },
    },
  };
}

export function deleteCard(id) {
  return {
    types: [DELETE_CARD, DELETE_CARD_SUCCESS, DELETE_CARD_FAIL],
    payload: {
      request: {
        method: "DELETE",
        url: `cards/${id}`,
        data: {
          id: id,
        },
      },
    },
  };
}
export function setActiveCard(id) {
  return {
    types: [SET_PRIMARY_CARD, SET_PRIMARY_CARD_SUCCESS, SET_PRIMARY_CARD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `users/me/cards/${id}/active`,
      },
    },
  };
}

export function otpValidation(card) {
  return {
    types: [VALIDATE_ACCOUNT, VALIDATE_ACCOUNT_SUCCESS, VALIDATE_ACCOUNT_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "charge/submit_otp",
        baseURL: config.REACT_APP_PAYSTACK_URL,
        headers: {
          Authorization: config.REACT_APP_PAYSTACK_SECRET_KEY,
          "Content-Type": "application/json",
        },
        data: {
          reference: card.txnRef,
          otp: card.otp,
        },
      },
    },
  };
}

export function verifyUser(code) {
  return {
    types: [VERIFY_USER, VERIFY_USER_SUCCESS, VERIFY_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "users/verify_email_or_phone",
        data: {
          verification_code: code,
        },
      },
    },
  };
}

export function resendVerificationCode() {
  return {
    types: [RESEND_CODE, RESEND_CODE_SUCCESS, RESEND_CODE_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `users/verify_email_or_phone/resend_code`,
      },
    },
  };
}

export function fetchTerminals() {
  return {
    types: [FETCH_TERMINALS, FETCH_TERMINALS_SUCCESS, FETCH_TERMINALS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `terminals`,
      },
    },
  };
}

export function createLead(data) {
  return {
    types: [CREATE_PARTNER, CREATE_PARTNER_SUCCESS, CREATE_PARTNER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "leads",
        data,
      },
    },
  };
}
