import styled from "styled-components";
import group from "../../assets/Group.svg";

const FeatureCard = ({ title, info, center, icon }) => {
  return (
    <Card center={center}>
      <img src={icon || group} alt={"logo"} />
      <h3>{title}</h3>
      <p>{info}</p>
    </Card>
  );
};

const Card = styled.div`
  background: white;
  text-align: ${({ center }) => center && "center"};
  width: 100%;
  line-height: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 40px 20px 20px;

  h3 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 25px;
    margin: 20px 0 17px;
  }
  p {
    color: ${({ theme }) => theme.colors.textOne};
  }
`;

export default FeatureCard;
