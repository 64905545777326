import LandingNavbar from "./LandingNavbar";
import { config } from "../../../config";
import { Button } from "antd";

const CompanyBanner = () => {
  return (
    <div
      className="h-screen w-full max-h-[1000px]"
      style={{ background: "url(/images/company-bg.jpeg)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
    >
      <LandingNavbar />
      <div className="flex items-center h-full">
        <div className="w-[90%] mx-auto">
          <div className="text-center sm:text-left">
            <h1 className="md:w-[75%] xl:w-[55%] my-0 text-[30px] sm:text-[40px] md:text-[50px] text-white leading-[40px] md:leading-[58px]">
              Effortless Freight Transportation at Your Fingertips
            </h1>
            <p className="md:w-[65%] xl:w-[45%] my-4 sm:my-6 text-white text-lg sm:text-xl font-[500]">
              Simplify Your Haulage Operations and Maximize Efficiency with Go Truck, Your Smart Haulage Solution.{" "}
            </p>

            <a href={`${config.REACT_APP_GOTRUCK_COMPANY}/register_company`}>
              <Button type="primary" size="large" style={{ padding: "14px 28px", height: "auto" }}>
                Get Started
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBanner;
