import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import {Divider} from "antd";



const TripsDropdown = ({ setDropdown }) => {
    return (
        <Dropdown onClick={() => setDropdown(false)}>
            <div className={'item'}>
                <Link to={'trips/upcoming'} className={'link'}>Upcoming Trips</Link>
            </div>
            <div className={'item'}>
                <Link className={'link'} to={'trips/history'}>Trip History</Link>
            </div>
        </Dropdown>
    )
}

const Dropdown = styled.ul`
  position: absolute;
  width: 200px;
  list-style: none;
  background: white;
  top: 10vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
  .hidden{
    display: none;
  }

  .item {
    height: 55px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
  }

  .link {
    height: 100%;
    color: black;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0 20px;
  }

  .link:hover {
    background: #f5f5f5;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .profile-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    margin: 0;

    .p2 {
      font-style: italic;
      font-size: 10px;
    }
  }

`



export default TripsDropdown;

