const validate = require("validate.js");

export var creditCardConstraints = {
    number: {
        presence: true,
        /*format: {
            pattern: /^(34|37|4|5[1-5]).*$/,
            message: function(value, attribute, validatorOptions, attributes, globalOptions) {
                return validate.format("Card number is not valid", {
                    num: value
                });
            }
        },*/
        length: function(value, attributes, attributeName, options, constraints) {
            if (value) {
                // Amex
                if ((/^(34|37).*$/).test(value)) return {is: 15};
                // Visa, Mastercard
                if ((/^(4|5[1-5]).*$/).test(value)) return {is: 16};
            }
            // Unknown card, don't validate length
            return false;
        }
    },

    /*name: {
        presence: true,
    },*/

    expiry_month: function(value, attributes, attributeName, options, constraints) {

        // if (1<=parseInt(value)<=12) return null;
        return {
            presence: {message: "is required"},
            length: {is: 2, message: "must be 2 characters"},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThanOrEqualTo: 12,
            }
        };
    },

    expiry_year: function(value, attributes, attributeName, options, constraints) {
        let currentYear = new Date().getFullYear().toString().substr(2,2);
        // if (1<=parseInt(value)<=12) return null;
        return {
            presence: {message: "is required"},
            length: {is: 2, message: "must be 2 characters"},
            numericality: {
                onlyInteger: true,
                greaterThan: parseInt(currentYear) - 1,
                lessThanOrEqualTo: 40,
            }
        };
    },

    email: {
        presence: true,
        email: true
    },
    creditCardZip: function(value, attributes, attributeName, options, constraints) {
        if (!(/^(34|37).*$/).test(attributes.creditCardNumber)) return null;
        return {
            presence: {message: "is required when using AMEX"},
            length: {is: 5}
        };
    }
};

export var userConstraints = {
    family_name:{
        presence: true,
    },
    given_name:{
        presence: true,
    },
    email:{
        presence: true,
        email: true
    },
    phone_number:{
        presence: true
    }
};

export var signUpConstraints = {
    family_name:{
        presence: true,
    },
    given_name:{
        presence: true,
    },
    email:{
        presence: true,
        email: true
    },
    phone_number:{
        presence: true
    },
    password: {
        presence: true,
        format: {
            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z0-9!:`?~;@#\\$%\\^\\&*\\)\\(+=._-]{6,}$",
            flags: "i",
            message: "has to be a Minimum of 6 characters, at least one uppercase, one lowercase, and one number"
        },
        length: {
            minimum: 6,
            message: "must be at least 6 characters"
        }
    },
    confirm_password: {
        equality: {
            attribute: "password",
            message: "^The passwords does not match"
        }
    }
};

export var loginConstraints = {
    email:{
        presence: true,
        email: true
    },
    password: {
        presence: true,
    }
};


export var TLLooseGoodsConstraints = {
    vehicle_type:{
        presence: true,
    },
    tdo_expiry_date:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    tdo_image:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },

    /*valid_card:{
        length: {
            minimum: 1,
            message: "needed to handle payment"
        }
    }*/
};
export var TLContainerConstraints = {
    container_number:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    tdo_expiry_date:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    tdo_image:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },

    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    item_size:{
        presence: true,
    },

    /*valid_card:{
        length: {
            minimum: 1,
            message: "needed to handle payment"
        }
    }*/
};



export var LTLooseGoodsConstraints = {
    vehicle_type:{
        presence: true,
    },
    waybill_image:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },

    /*valid_card:{
        length: {
            minimum: 1,
            message: "needed to handle payment"
        }
    }*/
};
export var LTContainerConstraints = {
    container_number:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    waybill_image:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },

    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    item_size:{
        presence: true,
    },

    /*valid_card:{
        length: {
            minimum: 1,
            message: "needed to handle payment"
        }
    }*/
};

export var LLLooseGoodsConstraints = {
    vehicle_type:{
        presence: true,
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
};

export var LLContainerConstraints = {
    container_number:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    start_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    end_address:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },

    start_time:{
        presence: true,
        length: {
            minimum: 1,
            message: "is needed"
        }
    },
    item_size:{
        presence: true,
    },
};

export var changePasswordConstraints = {
    password: {
        presence: true,
        // length: {
        //     minimum: 3,
        //     message: "must be at least 3 characters"
        // }
    },
    confirm_password: {
        presence: true,
        equality: {
            attribute: "password",
            message: "^The passwords does not match"
        }
    }
};
