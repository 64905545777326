import jwtDecode from 'jwt-decode'
import { ACCESS_TOKEN } from "../redux/constants";


export const getUser = () => {
    try {
        const jwtString = localStorage.getItem(ACCESS_TOKEN)
        const jwtArr = jwtString.split(" ") || [];
        const jwt = jwtArr[1]
        const user = jwtDecode(jwt);

        return user;
    }
    catch (ex){
        return null;
    }
};

