import { useState } from "react";
import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import AuthContainer from "./AuthContainer";
import { Button, Form, Input } from "antd";
import { Link, useLocation, Navigate } from "react-router-dom";
import { FormContainer } from "../../styles/form";
import { reduxFunctions } from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import { getUser } from "../../helpers/jwt";
import usePageTitle from "../../components/hooks/usePageTitle";

const Register = (props) => {
  const [data, setData] = useState({
    family_name: "",
    given_name: "",
    phone_number: "",
    email: "",
    password: "",
    user_type: "regular",
  });
  const location = useLocation();

  usePageTitle("Register");

  const { password } = data;

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const hasSixCharacters = password.trim()?.length >= 6;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

  const handleCreateUser = async () => {
    if (!hasSixCharacters) return toast.error("Password must contain at least 6 characters");
    if (!hasUpperCase) return toast.error("Password must contain at least 1 uppercase character");
    if (!hasLowerCase) return toast.error("Password must contain at least 1 lowercase character");
    if (!hasDigit) return toast.error("Password must contain at least 1 digit");
    if (!hasSpecialCharacter) return toast.error("Password must contain at least 1 special character");

    try {
      const res = await props.signUp(data);
      // console.log(res, 'response');
      if (res?.error) {
        throw new Error(res?.error?.response?.data?.message);
      }
      toast.success((t) => <span>Signed up successfully!</span>, {});
      return;
    } catch (e) {
      toast.error(
        (t) => <span>{e.toString().split(":")[1] ? e.toString().split(":")[1] : "Error signing up!"}</span>,
        {}
      );
      return e;
    }
  };

  if (getUser()) {
    return <Navigate to="/app" state={{ path: location.pathname }} />;
  }

  return (
    <AuthContainer>
      <FormContainer>
        <h1>Register</h1>

        <Form layout={"vertical"} onFinish={handleCreateUser}>
          <Form.Item
            label="First name"
            name="given_name"
            required={false}
            rules={[{ required: true, message: "Please input your first name!" }]}
            className="input-item"
          >
            <Input
              name="given_name"
              value={data.given_name}
              onChange={handleChange}
              className="input"
              placeholder="Enter your First name"
            />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="family_name"
            required={false}
            rules={[{ required: true, message: "Please input your last name!" }]}
            className="input-item"
          >
            <Input
              name="family_name"
              value={data.family_name}
              onChange={handleChange}
              className="input"
              placeholder="Enter your Last name"
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            required={false}
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            className="input-item"
          >
            <Input
              name="email"
              value={data.email}
              onChange={handleChange}
              className="input"
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone_number"
            required={false}
            rules={[{ required: true, message: "Please input your phone number!" }]}
            className="input-item"
          >
            <Input
              name="phone_number"
              value={data.phone_number}
              onChange={handleChange}
              className="input"
              placeholder="Enter your phone number"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            required={false}
            rules={[{ required: true, message: "Please input your password!" }]}
            className="input-item"
          >
            <Input.Password
              name="password"
              value={data.password}
              onChange={handleChange}
              className="input"
              placeholder="Enter your password"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? (
              <a onClick={() => toast.error("Coming soon!")}>Sign up</a>
            ) : props?.loading_signup ? (
              <ScaleLoader
                color={"#ffffff"}
                loading={props?.loading_signup}
                size={150}
                width={8}
                margin={5}
                height={30}
              />
            ) : (
              "Sign up"
            )}
          </Button>
          {/* <Button type="primary" htmlType="submit">
            {props?.loading_signup ? (
              <ScaleLoader
                color={"#ffffff"}
                loading={props?.loading_signup}
                size={150}
                width={8}
                margin={5}
                height={30}
              />
            ) : (
              "Sign up"
            )}
          </Button> */}
        </Form>
        <p className={"action"}>
          Already have an account? <Link to={"/login"}>Login</Link>
        </p>
      </FormContainer>
    </AuthContainer>
  );
};

const mapStateToProps = ({ user, loading_signup }) => ({ user, loading_signup });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Register);
