import styled from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";
import currency from "currency.js";
import moment from "moment";
import { Button, Divider, Image } from "antd";
import Modal from "react-awesome-modal";
import { AiOutlineClose } from "react-icons/ai";
import React from "react";
import { TRIP_STATE, VEHICLE_TYPES } from "../../constants/trip";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { getKeyByValue } from "../../helpers/utils";

const TripSummaryModal = ({
  visible,
  closeModal,
  data,
  ongoing,
  loading,
  update_trip_loading,
  updateTripStatus,
  getOngoing,
}) => {
  const { CANCELLED, PENDING, ACCEPTED } = TRIP_STATE;

  const handleTripCancel = async (id) => {
    try {
      const res = await updateTripStatus(id, CANCELLED);
      if (res?.error) {
        throw new Error(res.error?.response?.data?.message);
      }
      toast.success("Trip cancelled successfully");
      closeModal();
      getOngoing();
    } catch (error) {
      toast.error(`${error || "An error occured"}`);
      return error;
    }
  };

  // const canCancel = data?.state?.value === PENDING;

  return (
    <Modal visible={visible} width="700" height="90%" effect="fadeInUp" onClickAway={closeModal}>
      <Inner>
        <Head>
          <h1>Trip Summary</h1>
          <AiOutlineClose size={18} onClick={closeModal} className={"icon"} />
        </Head>
        <Divider />
        {!loading ? (
          <Content>
            {data?.start_address && (
              <Item>
                <p className={"p1"}>Pick up Location</p>
                <p className={"p2"}>{data?.start_address}</p>
              </Item>
            )}
            {data?.end_address && (
              <Item>
                <p className={"p1"}>Drop off Location</p>
                <p className={"p2"}>{data?.end_address}</p>
              </Item>
            )}

            {data?.drop_off_address?.length > 5 && (
              <Item>
                <p className={"p1"}>Empties Drop off Location</p>
                <p className={"p2"}>{data?.drop_off_address}</p>
              </Item>
            )}

            {data?.start_time && (
              <Item>
                <p className={"p1"}>Date</p>
                <p className={"p2"}>{moment(`${data?.start_time}`).format("Do MMMM, YYYY")}</p>
              </Item>
            )}

            {data?.cost_id?.formatted_distance && (
              <Item>
                <p className={"p1"}>Distance</p>
                <p className={"p2"}>{data?.cost_id?.formatted_distance}</p>
              </Item>
            )}

            {data?.cost_id?.formatted_duration && (
              <Item>
                <p className={"p1"}>Duration</p>
                <p className={"p2"}>{data?.cost_id?.formatted_duration}</p>
              </Item>
            )}

            {data?.tonnage && (
              <Item>
                <p className={"p1"}>Tonnage</p>
                <p className={"p2"}>{`${data?.tonnage} tons`}</p>
              </Item>
            )}

            {data?.item_size && (
              <Item>
                <p className={"p1"}>Container Size</p>
                <p className={"p2"}>{getKeyByValue(VEHICLE_TYPES, data?.item_size)}</p>
              </Item>
            )}

            {data?.tdo?.valid_until && (
              <Item>
                <p className={"p1"}>TDO Expiry Date</p>
                <p className={"p2"}>{moment(data?.tdo?.valid_until).format("Do MMMM, YYYY")}</p>
              </Item>
            )}

            {data?.tdo?.tdo_image && (
              <Item>
                <p className={"p1"}>TDO Image</p>
                <div className={"img-container"} onClick={closeModal}>
                  <Image
                    className={"img"}
                    width={100}
                    src={data?.tdo?.tdo_image}
                    // preview={{
                    //     src: {data?.tdo?.tdo_image},
                    // }}
                  />
                </div>
              </Item>
            )}

            <Divider />

            {ongoing && data?.state?.value && (
              <Item>
                <p className={"p1"}>Trip Status</p>
                <p className={"p2"}>{data?.state?.value}</p>
              </Item>
            )}

            {!ongoing && data?.cost_id?.tariff && (
              <Item>
                <p className={"p1"}>Tariff</p>
                <p className={"p2"}>{currency(data?.cost_id?.tariff).format({ symbol: "₦" })}</p>
              </Item>
            )}
            {!ongoing && data?.cost_id?.price && data?.cost_id?.price_upper_bound && (
              <Item>
                <p className={"p1"}>Total</p>
                <p className={"p2"}>
                  {currency(data?.cost_id?.price).format({ symbol: "₦" })}&nbsp;-&nbsp;
                  {currency(data?.cost_id?.price_upper_bound).format({ symbol: "₦" })}
                </p>
              </Item>
            )}
            <Divider />

            {/* {canCancel && (
              <div className="flex justify-end">
                <Button
                  type={"primary"}
                  size="large"
                  style={{ padding: "0px 30px" }}
                  onClick={() => handleTripCancel(data?._id)}
                >
                  {update_trip_loading ? (
                    <ScaleLoader
                      color={"#ffffff"}
                      loading={update_trip_loading}
                      size={100}
                      width={5}
                      margin={3}
                      height={15}
                    />
                  ) : (
                    "Cancel Trip"
                  )}
                </Button>
              </div>
            )} */}
          </Content>
        ) : (
          <Loading>
            <ScaleLoader color={"#e27626"} loading={loading} size={150} width={8} margin={5} height={30} />
          </Loading>
        )}
      </Inner>
    </Modal>
  );
};

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Inner = styled.div`
  //width: 85%;
  margin: 0 auto 10px;
  overflow: scroll;
  height: 100%;
  padding: 30px 50px 30px;

  .icon {
    cursor: pointer;
  }
`;

const Head = styled(Flex)`
  //margin-bottom: 10px;
  h1 {
    margin: 0;
  }
`;
const Content = styled.div``;

const Item = styled(Flex)`
  align-items: flex-start;
  margin-bottom: 8px;
  .p1 {
    width: 40%;
  }
  .p2 {
    width: 58%;
  }

  .img-container {
    margin: 0;
    width: 58%;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

const mapStateToProps = ({ update_trip_loading, updateTripStatus }) => ({ update_trip_loading, updateTripStatus });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripSummaryModal);
