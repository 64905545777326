import { useState } from "react";
import styled from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import AuthContainer from "./AuthContainer";
import { FormContainer } from "../../styles/form";
import { Button, Form, Input } from "antd";
import ResetPasswordLinkSent from "../../components/common/ResetPasswordLink";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { getUser } from "../../helpers/jwt";
import usePageTitle from "../../components/hooks/usePageTitle";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);

  usePageTitle("Forgot Password");

  const location = useLocation();

  const onFinish = async () => {
    try {
      const response = await props.requestPasswordResetEmail(email);
      if (response?.error) {
        throw new Error(response?.error);
      }
      return setVisible(true);
    } catch (error) {
      // console.log(error, 'error');
      return error;
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  if (getUser()) {
    return <Navigate to="/app" state={{ path: location.pathname }} />;
  }

  return (
    <AuthContainer>
      <StyledForgot>
        <h1>Forgot Password?</h1>
        <p>Please enter your email address so we can send you a link to reset your password.</p>
        <Form layout={"vertical"} onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            required={false}
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            className="input-item"
          >
            <Input
              name="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              className="input"
              placeholder="Enter your Email address"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {props.password_reset_email_loading ? (
              <ScaleLoader
                color={"#ffffff"}
                loading={props.password_reset_email_loading}
                size={150}
                width={8}
                margin={5}
                height={30}
              />
            ) : (
              "Submit"
            )}
          </Button>
          <Link to="/login" className={"back-btn"}>
            <IoArrowBack className={"icon"} />
            <span>Back to sign in</span>
          </Link>
        </Form>
      </StyledForgot>
      <ResetPasswordLinkSent visible={visible} closeModal={closeModal} />
    </AuthContainer>
  );
};

const StyledForgot = styled(FormContainer)`
  padding: 90px 25px 40px;
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
    padding: 0 30px;
    font-size: 17px;
  }

  button {
    margin: 20px 0;
  }

  .back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    margin-right: 10px;
  }

  @media (max-width: 400px) {
    p {
      padding: 0 10px;
    }
  }
`;

const mapStateToProps = ({ user, password_reset_email_loading }) => ({ user, password_reset_email_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
