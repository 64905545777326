import { ThemeProvider } from "styled-components";
import { Toaster } from "react-hot-toast";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./screens/Auth/Home";
import Register from "./screens/Auth/Register";
import "./App.less";
import Login from "./screens/Auth/Login";
import AppHome from "./screens/App";
import RequireAuth from "./components/common/RequireAuth";
import Settings from "./screens/App/settings";
import Services from "./screens/Auth/Services";
import Contact from "./screens/Auth/Contact";
import Logout from "./screens/App/Logout";
import PriceEstimator from "./screens/App/PriceEstimator";
import TripList from "./screens/App/TripList";
import RequestTrip from "./screens/App/RequestTrip";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import ResetPassword from "./screens/Auth/ResetPassword";
import AuthEstimator from "./screens/Auth/AuthEstimator";
import ScrollTop from "./components/common/ScrollTop";
import OngoingTrips from "./screens/App/OngoingTrips";
import AboutUs from "./screens/Auth/AboutUs";
import Profile from "./screens/App/settings/Profile";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import AuthHome from "./screens/Auth";
import PartnerWithUs from "./screens/Auth/PartnerWithUs";
import Quote from "./components/partner/Quote";
import TermsOfUse from "./screens/Auth/TermsOfUse";
import PrivacyPolicy from "./screens/Auth/PrivaryPolicy";
import SlAgreement from "./screens/Auth/SlAgreement";
import Company from "./screens/Company";

function App() {
  const theme = {
    colors: {
      primary: "#e27626",
      secondary: "#344054",
      first: "#fff5ee",
      second: "#65ffda",
      textOne: "#373737",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/app"
          element={
            <RequireAuth>
              {" "}
              <AppHome />{" "}
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to={"request"} />} />
          <Route path="request" element={<RequestTrip />} />
          <Route path="ongoing-trips" element={<OngoingTrips />} />
          <Route path="settings" element={<Settings />} />
          <Route path="profile" element={<Profile />} />
          <Route path="price-estimator" element={<PriceEstimator />} />
          <Route path="trips/:category" element={<TripList />} />
        </Route>
        <Route path="pwreset" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="signup" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path={"/"} element={<AuthHome />}>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="company" element={<Company />} />
          <Route path="price-estimator" element={<AuthEstimator />} />
          <Route path="services" element={<Services />} />
          <Route path={"contact"} element={<Contact />} />
          <Route path={"partner"} element={<PartnerWithUs />} />
          <Route path={"quote"} element={<Quote />} />
          <Route path={"policy"} element={<PrivacyPolicy />} />
          <Route path={"terms"} element={<TermsOfUse />} />
          <Route path={"sla"} element={<SlAgreement />} />
        </Route>
      </Routes>
      <ScrollTop />
      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {},

          // Default options for specific types
          success: {
            icon: <IoMdCheckmarkCircleOutline size={20} />,
            style: { background: "#f6fef9", color: "#28ae60", border: "1px solid #28ae60" },
          },
          error: {
            icon: <BiErrorCircle size={20} />,
            style: { background: "##fffafa", color: "#b42219", border: "1px solid #b42219" },
          },
        }}
      />
    </ThemeProvider>
  );
}

export default App;
