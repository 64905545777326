import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../../assets/go-logo.svg'


const AuthContainer = (props) => {
    return (
        <StyledAuth>
            <Content>
                <div className ='head'>
                    <Link to={'/'}>
                    <img src={logo} alt={'logo'} />
                    </Link>
                </div>
                <div className ='body'>
                    {props.children}
                </div>

            </Content>
            <ShapeFill>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                     preserveAspectRatio="none">
                    <path
                        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                        className="shape-fill"></path>
                </svg>
            </ShapeFill>

        </StyledAuth>
    )
};

const StyledAuth = styled.div`
  position: relative;
  background: rgb(6, 107, 217);
  background: linear-gradient(
          90deg,
          rgb(249,169,68) 0%,
          rgb(247,113,32) 86%
  );
  color: black;
  margin: 0;
  min-height: 100vh;
  z-index: 1;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  
  .head{
    width: 90%;
    margin: 10px auto;
  }
  //img{
  //  width: 50px;
  //  height: 50px;
  //}
  .body{
    width: 35%;
    margin: 0 auto
  }
  
  @media (max-width: 1100px){
    .body{
      width: 50%;
    }
  }

  @media (max-width: 600px){
    .body{
      padding-top: 30px;
      width: 90%;
    }
  }
`


const ShapeFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  
  svg{
    position: relative;
    display: block;
    width: calc(141% + 1.3px);
    height: 550px;
  }
  
  .shape-fill {
    fill: #FFF;
  }
  
`

export default AuthContainer;