import { Outlet } from "react-router-dom";
import AppNavbar from "../../components/layout/AppNavbar";

const AppHome = () => {
  return (
    <>
      <AppNavbar />
      <Outlet />
    </>
  );
};

export default AppHome;
