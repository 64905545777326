import axios from "axios";
import {
  ACCESS_TOKEN,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_FAIL,
  SIGNUP_USER_SUCCESS,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  GET_PAST_TRIPS,
  GET_PAST_TRIPS_SUCCESS,
  GET_PAST_TRIPS_FAIL,
  GET_TRIP,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAIL,
  GET_ONGOING_TRIPS,
  GET_ONGOING_TRIPS_FAIL,
  GET_ONGOING_TRIPS_SUCCESS,
  GET_UPCOMING_TRIPS,
  GET_UPCOMING_TRIPS_SUCCESS,
  GET_UPCOMING_TRIPS_FAIL,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_FAIL,
  CREATE_COST,
  CREATE_COST_SUCCESS,
  CREATE_COST_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  UPDATE_TRIP_STATUS,
  UPDATE_TRIP_STATUS_SUCCESS,
  UPDATE_TRIP_STATUS_FAIL,
  CREATE_TRIP,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAIL,
  INITIATE_PAYMENT,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAIL,
  CREATE_CARD,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  GET_CARDS,
  GET_CARDS_SUCCESS,
  GET_CARDS_FAIL,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAIL,
  SET_PRIMARY_CARD,
  SET_PRIMARY_CARD_SUCCESS,
  SET_PRIMARY_CARD_FAIL,
  VALIDATE_ACCOUNT,
  VALIDATE_ACCOUNT_SUCCESS,
  VALIDATE_ACCOUNT_FAIL,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  FETCH_TERMINALS,
  FETCH_TERMINALS_SUCCESS,
  FETCH_TERMINALS_FAIL,
  TRACK_TRIP_FAIL,
  TRACK_TRIP_SUCCESS,
  TRACK_TRIP,
  CREATE_PARTNER,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAIL,
} from "../constants";

const initialState = {
  user: {},
  past_trips: [],
  ongoing_trips: [],
  track_trip_loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_USER:
      return { ...state, loading_signup: true };
    case SIGNUP_USER_SUCCESS:
      const signup_token = `Bearer ${action.payload.data.oauth.token}`;
      localStorage.setItem(ACCESS_TOKEN, signup_token);
      axios.defaults.headers.common["Authorization"] = signup_token;
      return { ...state, loading_signup: false, user: action.payload.data.user };
    case SIGNUP_USER_FAIL:
      return { ...state, loading_signup: false, error: " " };

    case LOGIN_USER:
      return { ...state, loading_login: true };
    case LOGIN_USER_SUCCESS:
      const login_token = "Bearer " + action.payload.data.oauth.token;
      localStorage.setItem(ACCESS_TOKEN, login_token);
      axios.defaults.headers.common["Authorization"] = login_token;
      return { ...state, loginSuccess: true, loading_login: false, user: action.payload.data.user, token: login_token };
    case LOGIN_USER_FAIL:
      return { ...state, loading_login: false, login_success: false, error: "Error Logging IN USER " };

    case GET_USER:
      return { ...state, loading_user: true };
    case GET_USER_SUCCESS:
      return { ...state, loading_user: false, user: action.payload.data.user };
    case GET_USER_FAIL:
      return { ...state, loading_user: false, error: "Error sending Trip info" };

    case GET_PAST_TRIPS:
      return { ...state, loading_past_trips: true, past_trip_page: action.payload.data.page };
    case GET_PAST_TRIPS_SUCCESS:
      let past_trips = [];
      if (state.past_trip_page > 0) {
        past_trips = state.past_trips.concat(action.payload.data.trips);
      } else {
        past_trips = action.payload.data.trips;
      }
      return { ...state, loading_past_trips: false, past_trips: past_trips, past_trip_page: 0 };
    case GET_PAST_TRIPS_FAIL:
      return { ...state, loading_past_trips: false, error: "Error getting past trips info" };

    case GET_TRIP:
      return { ...state, loading_trip: true };
    case GET_TRIP_SUCCESS:
      return { ...state, loading_trip: false, trip: action.payload.data.trip };
    case GET_TRIP_FAIL:
      return { ...state, loading_trip: false, error: "Error sending Trip info" };

    case GET_ONGOING_TRIPS:
      return { ...state, loading_ongoing_trips: true };
    case GET_ONGOING_TRIPS_SUCCESS:
      return { ...state, loading_ongoing_trips: false, ongoing_trips: action.payload.data.trips };
    case GET_ONGOING_TRIPS_FAIL:
      return { ...state, loading_ongoing_trips: false, error: "Error getting ongoing trips" };

    case GET_UPCOMING_TRIPS:
      return { ...state, loading_upcoming_trips: true };
    case GET_UPCOMING_TRIPS_SUCCESS:
      return { ...state, loading_upcoming_trips: false, upcoming_trips: action.payload.data.trips };
    case GET_UPCOMING_TRIPS_FAIL:
      return { ...state, loading_upcoming_trips: false, error: "Error getting ongoing trips" };

    case PASSWORD_RESET_EMAIL:
      return { ...state, password_reset_email_loading: true };
    case PASSWORD_RESET_EMAIL_SUCCESS:
      return { ...state, password_reset_email_loading: false };
    case PASSWORD_RESET_EMAIL_FAIL:
      return { ...state, password_reset_email_loading: false };

    case CREATE_COST:
      return { ...state, create_cost_loading: true };
    case CREATE_COST_SUCCESS:
      return { ...state, create_cost_loading: false };
    case CREATE_COST_FAIL:
      return { ...state, create_cost_loading: false };

    case UPDATE_USER:
      return { ...state, update_user_loading: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, update_user_loading: false };
    case UPDATE_USER_FAIL:
      return { ...state, update_user_loading: false, error: "Error sending Trip info" };

    case CHANGE_PASSWORD:
      return { ...state, change_password_loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, change_password_loading: false };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, change_password_loading: false, error: "Error sending Trip info" };

    case UPDATE_TRIP_STATUS:
      return { ...state, update_trip_loading: true };
    case UPDATE_TRIP_STATUS_SUCCESS:
      return { ...state, update_trip_loading: false };
    case UPDATE_TRIP_STATUS_FAIL:
      return { ...state, update_trip_loading: false, error: "Error sending Trip info" };

    case CREATE_TRIP:
      return { ...state, create_trip_loading: true };
    case CREATE_TRIP_SUCCESS:
      return { ...state, create_trip_loading: false };
    case CREATE_TRIP_FAIL:
      return { ...state, create_trip_loading: false, error: "Error creating a trip" };

    case TRACK_TRIP:
      return { ...state, track_trip_loading: true };
    case TRACK_TRIP_SUCCESS:
      return { ...state, track_trip_loading: false };
    case TRACK_TRIP_FAIL:
      return { ...state, track_trip_loading: false, error: "Tracking failed. Please confirm tracking ID" };

    case INITIATE_PAYMENT:
      return { ...state, loading_pay: true };
    case INITIATE_PAYMENT_SUCCESS:
      return { ...state, loading_pay: false, validation_info: action.payload.data.data };
    case INITIATE_PAYMENT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case CREATE_CARD:
      return { ...state, loading_pay: true };
    case CREATE_CARD_SUCCESS:
      return { ...state, loading_pay: false };
    case CREATE_CARD_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case GET_CARDS:
      return { ...state, loading_cards_get: true };
    case GET_CARDS_SUCCESS:
      return { ...state, loading_cards_get: false, card_list: action.payload.data.cards };
    case GET_CARDS_FAIL:
      return { ...state, loading_cards_get: false, error: "Error sending Trip info" };

    case DELETE_CARD:
      return { ...state, loading_card_delete: true };
    case DELETE_CARD_SUCCESS:
      return { ...state, loading_card_delete: false };
    case DELETE_CARD_FAIL:
      return { ...state, loading_card_delete: false, error: "Error sending Trip info" };

    case SET_PRIMARY_CARD:
      return { ...state, loading_primary_card: true };
    case SET_PRIMARY_CARD_SUCCESS:
      return { ...state, loading_primary_card: false };
    case SET_PRIMARY_CARD_FAIL:
      return { ...state, loading_primary_card: false, error: "Error sending Trip info" };

    case VALIDATE_ACCOUNT:
      return { ...state, loading_validate_account: true };
    case VALIDATE_ACCOUNT_SUCCESS:
      let token = {};
      if (action?.payload?.data?.data?.status == "success") {
        token = action.payload.data.data.authorization;
      }
      return { ...state, loading_validate_account: false, card_token: token };
    case VALIDATE_ACCOUNT_FAIL:
      return { ...state, loading_validate_account: false, error: "Error sending Trip info" };

    case VERIFY_USER:
      return { ...state, loading_verify_user: true };
    case VERIFY_USER_SUCCESS:
      return { ...state, loading_verify_user: false };
    case VERIFY_USER_FAIL:
      return { ...state, loading_verify_user: false };

    case FETCH_TERMINALS:
      return { ...state, loading_fetch_terminals: true };
    case FETCH_TERMINALS_SUCCESS:
      return { ...state, loading_fetch_terminals: false };
    case FETCH_TERMINALS_FAIL:
      return { ...state, loading_fetch_terminals: false };

    case CREATE_PARTNER:
      return { ...state, loading_lead: true };
    case CREATE_PARTNER_SUCCESS:
      return { ...state, loading_lead: false };
    case CREATE_PARTNER_FAIL:
      return { ...state, loading_lead: false };

    default:
      return state;
  }
};

export default reducer;
