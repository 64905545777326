
export default class CreditCard {
    _id;
    name;
    number;
    cvv;
    expiry_month;
    expiry_year;
    email;
    card_token;
    txnRef;
    otp;
    card_alias;
    // card_type;


    constructor() {
        this.paymentType = 'card'
    }

    setCardType(){

    }

    setCardAlias(){
        /*let first = this.number.substring(0, 5);
        let last = this.number.substring(this.card_no.length - 4, this.card_no.length);
        this.card_alias = first + '-' + last;*/
        let first = this.card_token.last4;
        let middle = this.card_token.signature
        let last = this.txnRef;
        this.card_alias = first + '-' + middle + '-' + last;
    }

    // setCardType(){
    //     var card = creditCardType(this.number);
    //     this.card_type = card[0].niceType;
    // }

    getServerCard(){
        this.setCardAlias();
        this.setCardType();
        return({
            "card_alias":this.card_alias,
            "card_type":this.card_token.card_type,
            "card_token": this.card_token.authorization_code
        })
    }

}
