import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Divider, Form, Input, Radio, Spin } from "antd";
import { ContentContainer, Head } from "../../../styles/settings";
import PaymentCard from "./components/PaymentCard";
import SelectCard from "./components/SelectCard";
import CardModal from "../../../components/common/CardModal";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import toast from "react-hot-toast";
import { deleteCard } from "../../../redux/actions/mainActions";
import BillingCard from "../../../components/settings/BillingCard";

const PaymentMethod = (props) => {
  const [method, setMethod] = useState("card");
  const [visible, setVisible] = useState(false);
  const [cards, setCards] = useState(props.card_list);
  const [defaultBank, setDefaultBank] = useState("");

  const handleMethodTarget = ({ target }) => {
    setMethod(target.value);
  };
  const handleMethodValue = (value) => {
    setMethod(value);
  };

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const { loading_card_delete, loading_primary_card, loading_pay, loading_cards_get } = props;

  const getCards = async () => {
    try {
      const res = await props.getCardList();
      if (res?.error) {
        throw new Error(res?.error?.response?.data?.message);
      }
      setCards(res?.payload?.data?.cards);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await props.deleteCard(id);
      if (res?.error) {
        throw new Error(res?.error?.response?.data?.message);
      }
      getCards();
      toast.success((t) => <span>Card successfully deleted.</span>, {});
      return;
    } catch (e) {
      toast.error((t) => <span>Error deleting card.</span>, {});
      return e;
    }
  };

  const setPrimary = async (id) => {
    try {
      const res = await props.setActiveCard(id);
      if (res?.error) {
        throw new Error(res?.error?.response?.data?.message);
      }
      getCards();
      toast.success((t) => <span>Card successfully set as primary.</span>, {});
    } catch (e) {
      toast.error((t) => <span>Error setting card.</span>, {});
      return e;
    }
  };

  const activeCard = cards?.find((a) => a.active);
  const notActiveCards = cards?.filter((a) => !a.active);

  const sortedCards = activeCard ? [activeCard, ...notActiveCards] : notActiveCards ? [...notActiveCards] : [];

  return (
    <StyledPayment>
      <Head>
        <div>
          <h2>Payment Method</h2>
          <p>Update your payment method</p>
        </div>
        {/* <div className={"btn-container"}>
          <Button type={""} size={"large"}>
            cancel
          </Button>
          <Button type={"primary"} size={"large"} className={"btn"}>
            Save
          </Button>
        </div> */}
      </Head>
      <Divider />

      <Method>
        <div className={"item"} onClick={() => handleMethodValue("card")}>
          <PaymentCard value={"card"} title={"Card"} info={"Choose a card as the payment method for the next trip."}>
            <Radio checked={method === "card"} name={"card"} onChange={handleMethodTarget} value={"card"}></Radio>
          </PaymentCard>
        </div>

        {/* <div className={'item'} onClick={() => handleMethodValue('wallet')}>
                    <PaymentCard value={'wallet'} title={'Wallet'} info={'Choose wallet as the payment method for the next trip.'}>
                        <Radio checked={method === 'wallet'} name={'wallet'} onChange={handleMethodTarget} value={'wallet'}></Radio>
                    </PaymentCard>
                </div> */}
      </Method>

      {method === "card" && (
        <CardOptions>
          <h2>Add and Choose Cards</h2>
          <Divider />

          {loading_cards_get || loading_primary_card || loading_card_delete ? (
            <div className="min-h-[250px] flex justify-center items-center">
              <Spin size="default" />
            </div>
          ) : (
            <div className="max-w-[700px] space-y-4 mb-6">
              {/* {cards?.length > 0 ? (
                cards?.map((card) => {
                  return (
                    <div key={card._id}>
                      <SelectCard
                        title={`Card ending with ${card?.last_four_digits} `}
                        image={"/card.png"}
                        handleDelete={handleDelete}
                        card={card}
                      >
                        <Radio
                          checked={card?.active}
                          name={card._id}
                          onChange={() => setPrimary(card._id)}
                          value={card._id}
                        ></Radio>
                      </SelectCard>
                    </div>
                  );
                })
              ) : (
                <Nocards>
                  <p>You currently do not have a card.</p>
                </Nocards>
              )} */}

              {sortedCards?.length > 0 ? (
                sortedCards?.map((card) => {
                  return (
                    <BillingCard
                      detail={card}
                      key={card?._id}
                      defaultBank={defaultBank}
                      setDefaultBank={setDefaultBank}
                      onDelete={handleDelete}
                      handleRadio={setPrimary}
                    />
                  );
                })
              ) : (
                <Nocards>
                  <p>You currently do not have a card.</p>
                </Nocards>
              )}
            </div>
          )}

          {!loading_cards_get && !loading_primary_card && !loading_card_delete && (
            <p className={"add"} onClick={openModal}>
              +Add New Card
            </p>
          )}
        </CardOptions>
      )}

      <div className={"btn-container-2"}>
        <Button type={""} size={"large"}>
          cancel
        </Button>
        <Button type={"primary"} size={"large"} className={"btn"}>
          Save
        </Button>
      </div>

      <CardModal visible={visible} closeModal={closeModal} addCardSuccess={getCards} />
    </StyledPayment>
  );
};

const StyledPayment = styled(ContentContainer)``;

const Method = styled.div`
  display: flex;
  padding-bottom: 80px;

  .item {
    width: 40%;
    margin-right: 35px;
  }

  @media (max-width: 1050px) {
    justify-content: space-between;

    .item {
      width: 48%;
      margin-right: 0;
    }
  }

  @media (max-width: 750px) {
    display: block;

    .item {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const CardOptions = styled.div`
  .add {
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 15px;
  }
`;
const Nocards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const mapStateToProps = ({
  user,
  loading_card_delete,
  loading_cards_get,
  card_list,
  loading_primary_card,
  loading_pay,
}) => ({
  user,
  loading_card_delete,
  card_list,
  loading_primary_card,
  loading_pay,
  loading_cards_get,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
