const FeatureCard = ({ data: { icon, title, text } }) => {
  return (
    <div
      className="w-full bg-[#FDFEFF] duration-300 transition-all flex flex-col items-center text-center hover:shadow p-6 rounded-md space-y-4"
      style={{ border: "1px solid #D8D8DA80" }}
    >
      <span className="w-20 h-20 rounded-full flex justify-center items-center bg-[#FFF5EE]">
        <img src={icon} alt={title} className="max-w-[40px]" />
      </span>
      <h2>{title}</h2>
      <p className="text-[#96969C] text-base">{text}</p>
    </div>
  );
};

const Features = () => {
  const featuresData = [
    {
      icon: "/icons/trip.svg",
      title: "Best price estimates",
      text: "As our family of carriers grows, we're able to source more competitive prices and show the price estimate to you before trips begin, we're your best in this market.",
    },
    {
      icon: "/icons/send-icon.svg",
      title: "Efficient trucking",
      text: "Experience real-time tracking through our platform, offering a visual overview of all ongoing trips. That way you spend less time worrying about cargo logistics and more time fostering on business growth.",
    },
    {
      icon: "/icons/edit.svg",
      title: "24/7 Dedicated support",
      text: "Our commitment is to provide you with the utmost service excellence, with our automated dashboards, around-the-clock customer support, and a live load tracking system.",
    },
  ];

  return (
    <section className="w-[90%] mx-auto space-y-8 lg:space-y-12">
      <div className="flex flex-col md:flex-row gap-6 md:gap-10 items-center">
        <h2 className="text-[#344054] font-semibold text-center md:text-left text-3xl xl:text-4xl w-full md:w-[40%]">
          Simplified Transportation Services with Go Truck
        </h2>

        <p className="text-[#90999E] text-xl text-center md:text-left xl:text-2xl font-light w-full md:w-[60%] leading-10">
          Go Truck, a mobile and web-based platform, dedicated to delivering on-demand land freight transportation
          services to users in Nigeria. The platform focuses on enhancing the transportation experience for both
          individuals and companies, aiming to streamline the logistics process.
        </p>
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-12">
        {featuresData.map((data, i) => (
          <FeatureCard key={i} data={data} />
        ))}
      </div>
    </section>
  );
};

export default Features;
