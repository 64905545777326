import { useState, useEffect } from "react";
import queryString from "query-string";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import AuthContainer from "./AuthContainer";
import { FormContainer } from "../../styles/form";
import { Button, Form, Input } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { getUser } from "../../helpers/jwt";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import usePageTitle from "../../components/hooks/usePageTitle";

const ResetPassword = (props) => {
  const [token, setToken] = useState();
  const [data, setData] = useState({ password: "", confirm_password: "" });
  const [loading, setLoading] = useState(false);

  usePageTitle("Reset Password");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const result = queryString.parse(location.search);
    setToken(result.token);
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const onFinish = async () => {
    if (data.password !== data.confirm_password) return toast.error("Passwords do not match");
    setLoading(true);

    try {
      const response = await props.resetPassword(data.password, token);
      setLoading(false);
      if (response?.error) return toast.error(response?.error.response.data.message);

      toast.success("Password reset successful");
      navigate("/login");
    } catch (error) {
      setLoading(false);
      toast.error(error);
      return;
    }
  };

  if (getUser()) {
    return <Navigate to="/app" state={{ path: location.pathname }} />;
  }

  return (
    <AuthContainer>
      <DetailedContainer>
        <h1>Reset Password</h1>
        <Form layout={"vertical"} onFinish={onFinish}>
          <Form.Item
            label="New Password"
            name="password"
            required={false}
            rules={[{ required: true, message: "Please input your password!" }]}
            className="input-item"
          >
            <Input.Password
              name="password"
              value={data.password}
              onChange={handleChange}
              className="input"
              placeholder="Enter password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            required={false}
            rules={[{ required: true, message: "Please input your password!" }]}
            className="input-item"
          >
            <Input.Password
              name="confirm_password"
              value={data.confirm_password}
              onChange={handleChange}
              className="input"
              placeholder="Confirm password"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <BeatLoader color={"#ffffff"} loading={loading} size={10} /> : "Reset"}
          </Button>
        </Form>
      </DetailedContainer>
    </AuthContainer>
  );
};

const DetailedContainer = styled(FormContainer)`
  h1 {
    text-align: center;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
