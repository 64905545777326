import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import { reduxFunctions } from "../../helpers/reduxHelper";
import Footer from "../layout/Footer";
import Move from "../layout/Home/Move";
import usePageTitle from "../hooks/usePageTitle";

const Quote = (props) => {
  const [data, setData] = useState({});

  usePageTitle("Get a Quote");

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const createLead = async (data) => {
    try {
      const res = await props.createLead(data);
      if (res?.error) {
        throw new Error("Opps, an error occured");
      }
      setData({});
      toast.success("Submitted successfully");
    } catch (e) {
      toast.error("Opps, an error occured");
    }
  };

  const handleSubmit = () => {
    if (!data?.first_name || !data?.last_name || !data?.phone_number || !data?.email || !data?.description)
      return toast.error("Please fill all required fields");

    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      company: data?.company_name,
      phone: data?.phone_number,
      email: data?.email,
      business_type: "Inquiry",
      additional_information: data?.description,
      lead_source: "Website/Quote",
    };

    createLead(payload);
  };

  return (
    <>
      <div className="bg-[#fef5ed]">
        <div className="w-[90%] mx-auto space-y-4 py-20 flex flex-col items-center text-center">
          <h2 className="text-4xl">Get a Quote</h2>
          <p className="max-w-[800px]">
            Looking to move your cargo with precision and speed? Our expert logistics team is here to provide you with
            competitive pricing and exceptional service tailored to your unique transportation needs. Contact us today
            to receive a quote for your next haulage project.
          </p>
        </div>
      </div>

      <div className="flex justify-center my-20">
        <FormContainer>
          <Form layout={"vertical"} onFinish={() => {}}>
            <Form.Item
              label="First Name"
              required={false}
              rules={[{ required: true, message: "Please input your first name" }]}
              className="input-item"
            >
              <Input name="first_name" value={data.first_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              required={false}
              rules={[{ required: true, message: "Please input your last name" }]}
              className="input-item"
            >
              <Input name="last_name" value={data.last_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Company Name (Optional)"
              required={false}
              rules={[{ required: false, message: "Please input your company name" }]}
              className="input-item"
            >
              <Input name="company_name" value={data.company_name} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              required={false}
              rules={[{ required: true, message: "Please input your phone number" }]}
              className="input-item"
            >
              <Input name="phone_number" value={data.phone_number} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Email Address"
              required={false}
              rules={[
                { required: true, message: "Please input your email" },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              className="input-item"
            >
              <Input name="email" value={data.email} onChange={handleChange} className="input" />
            </Form.Item>

            <Form.Item
              label="Description of Goods"
              required={false}
              rules={[{ required: true, message: "Please input your message!" }]}
              className="input-item"
            >
              <Input.TextArea
                name="description"
                value={data.description}
                onChange={handleChange}
                style={{ minHeight: "100px" }}
                className="input"
              />
            </Form.Item>
            <div className="mt-10 btn-container">
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                {props?.loading_lead ? (
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={props?.loading_lead}
                    size={100}
                    width={5}
                    margin={3}
                    height={15}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </FormContainer>
      </div>

      <Move width={"100%"} />
      <Footer width={"90%"} />
    </>
  );
};

const FormContainer = styled.div`
  width: 100%;
  max-width: 700px;
  border: 5px solid #fafafa;
  border-radius: 5px;
  padding: 30px;

  h4 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;
    font-size: 18px;
  }

  .input {
    height: 40px;
    border-radius: 5px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  button {
    height: 40px;
    border-radius: 5px;
    width: 120px;
    margin: 0 auto;
  }

  @media (max-width: 750px) {
    width: 100%;
    border: none;
  }
`;

const mapStateToProps = ({ loading_lead }) => ({ loading_lead });

const mapDispatchToProps = reduxFunctions;
export default connect(mapStateToProps, mapDispatchToProps)(Quote);
