import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Button } from "antd";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import logo from "../../assets/go-logo.svg";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { config } from "../../config";

const Footer = ({ width }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  const emailValid = regex.test(email);

  const addContact = () => {
    if (!email || !emailValid) return toast.error("Please enter a valid email");

    setLoading(true);
    axios({
      url: "https://api.sendgrid.com/v3/marketing/contacts",
      headers: {
        Authorization: `Bearer ${config.REACT_APP_SENDGRID_API_KEY}`,
      },
      method: "PUT",
      data: {
        contacts: [
          {
            email: email,
          },
        ],
      },
    })
      .then((res) => {
        setLoading(false);
        toast.success("Subscribed successfully");
        setEmail("");
      })
      .catch((e) => {
        setLoading(false);
        toast.error("An error occured");
      });
  };

  const socialLinks = [
    {
      path: "https://www.linkedin.com/company/go-truck/",
      title: "LinkedIn",
      icon: "/icons/linkedin.svg",
    },
    { path: "https://www.instagram.com/gotruck_io/", title: "Instagram", icon: "/icons/instagram.svg" },
    {
      path: "https://twitter.com/gotruck_io?t=_m_WKu28dnsGwSX3bM817A&s=09",
      title: "Twitter",
      icon: "/icons/twitter.svg",
    },
    { path: "https://www.facebook.com/gotruck.io", title: "Facebook", icon: "/icons/facebook.svg" },
  ];

  return (
    <StyledFooter>
      <Inner width={width}>
        <Item>
          <NavLink to={"/"}>
            <img src={logo} alt={"logo"} className="pb-4 max-h-12" />
          </NavLink>
          <List>
            <li className="list-content">
              <a href={"tel:+2348101447225"}>
                <BsTelephone color={"#e27626"} className="icon" /> +234 810 144 7225
              </a>
            </li>
            <li className="list-content">
              <a href="mailto:admin@gotruck.com">
                <AiOutlineMail color="#e27626" className="icon" /> admin@gotruck.io
              </a>
            </li>
            <div className="flex items-center md:gap-3 ml-9">
              {socialLinks?.map(
                ({ path, title, icon }, i) =>
                  path && (
                    <a
                      key={title}
                      title={title}
                      target="_blank"
                      href={path}
                      className="mr-2 cursor-pointer md:mr-0"
                      rel="noreferrer"
                    >
                      <img
                        src={icon}
                        alt={`gotruck ${title}`}
                        className="transition-transform duration-300 hover:-translate-y-1 hover:scale-110 w-4"
                      />
                    </a>
                  )
              )}
            </div>
          </List>
        </Item>
        <Item>
          <h3>Useful links</h3>
          <List>
            <li>
              <Link to="/partner">Partner with us</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="#">FAQs</Link>
            </li>
          </List>
        </Item>
        <Item>
          <h3>Company</h3>
          <List>
            <li>
              <Link to="/policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Use</Link>
            </li>
            <li>
              <Link to="/sla">Service Level Agreement</Link>
            </li>
          </List>
        </Item>
        <Item>
          <h3>Newsletter</h3>
          <div className="flex items-center">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full"
            />
            <Button type="primary" onClick={addContact} className="min-w-[100px]">
              {loading ? <BeatLoader color={"#ffffff"} loading={loading} size={10} /> : "Subscribe"}
            </Button>
          </div>
        </Item>
      </Inner>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: 50px 0px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  background: linear-gradient(
    136.16deg,
    rgba(222, 217, 200, 0.1) -5.6%,
    rgba(255, 255, 255, 0.1) 50.79%,
    rgba(247, 107, 28, 0.1) 114.71%
  );
`;

const Inner = styled.div`
  width: ${({ width }) => width};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.secondary};
  flex-wrap: wrap;
`;

const Item = styled.div`
  input {
    height: 50px;
    padding-left: 10px;
    background: white;
    border-radius: 5px;
    border: 1px darkgray solid;
    margin-right: 5px;
    flex: 1;
  }

  button {
    height: 50px;
  }

  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    align-items: center;
    color: #888888;
  }

  a:hover {
    color: #e27626;
  }

  .icon {
    margin-right: 20px;
  }

  .list-content {
    a {
      color: #344054;
    }
  }
`;

export default Footer;
