import styled from 'styled-components';
import Lottie from "react-lottie";
import truck from '../../assets/lotties/truck.json'
import Modal from "react-awesome-modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import React from "react";
import {Button} from "antd";


const SearchingDriverModal = ({ visible, loading, setRefreshed, refreshed }) => {
    return (

        <Modal visible={visible} width="600" height="300" effect="fadeInUp" onClickAway={() => {}}>
            <Inner>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: truck,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                    },
                }} height={"80%"} width={"80%"} />
                <p>Searching for drivers...</p>
            </Inner>
        </Modal>
    )
}

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  
  p{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export default SearchingDriverModal;