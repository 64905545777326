import styled from "styled-components";
import Footer from "../../components/layout/Footer";
import usePageTitle from "../../components/hooks/usePageTitle";

const terms = [
  "Application means the software program provided by the Company downloaded by You on any electronic device, named Go Truck",
  "Application Store means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.",
  "Affiliate means an entity that controls, is controlled by or is under, common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
  "Account means a unique account created for You to access our Service or parts of our Service.",
  "Country refers to: Nigeria",
  'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Go Truck LTD, 45 Warehouse Rd Apapa.',
  "Content refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.",
  "Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
  "Feedback means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.",
  "Goods refer to the items offered for sale on the Service.",
  "Orders mean a request by You to purchase Goods from Us.",
  "Service refers to the Application.",
  'Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.',
  "Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.",
  "You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
];

const TermsOfUse = () => {
  usePageTitle("Terms of Use");
  return (
    <>
      <Container>
        <Head>
          <h1>Terms of Use</h1>
          <p>Last updated: March 29, 2022</p>
        </Head>
        <Content>
          <p>Please read these terms and conditions carefully before using Our Service.</p>
          <p>Interpretations</p>
          <p>---------------</p>
          <p className={"p-margin"}>
            The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.
          </p>
          <p>Definitions</p>
          <p>-----------</p>
          <p>For the purpose of these terms and conditions:</p>
          <ul>
            {terms.map((term, index) => (
              <li key={index}>
                <p>{term}</p>
              </li>
            ))}
          </ul>

          <div className={"detail"}>
            <p className={"left"}>Acknowledgment</p>
            <div className={"right"}>
              <p>
                These are the Terms and Conditions governing the use of this Service and the agreement that operates
                between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                regarding the use of the Service.
              </p>

              <p>
                {" "}
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or
                use the Service.
              </p>

              <p>
                {" "}
                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
                with any part of these Terms and Conditions then You may not access the Service.
              </p>

              <p>
                You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                Service.
              </p>

              <p>
                Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
                Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy
                carefully before using Our Service.
              </p>
            </div>
          </div>

          <div className={"detail"}>
            <p className={"left"}>Placing Orders for Goods </p>
            <div className={"right"}>
              <p>
                By placing an Order for Goods through the Service, You warrant that You are legally capable of entering
                into binding contracts.
              </p>
              <p className={"sub-title"}>Your Information</p>
              <p>
                If You wish to place an Order for Goods available on the Service, You may be asked to supply certain
                information relevant to Your Order including, without limitation, Your name, Your email, Your phone
                number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your
                shipping information.
              </p>

              <p>
                You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other
                payment method(s) in connection with any Order; and that (ii) the information You supply to us is true,
                correct and complete.
              </p>

              <p>
                By submitting such information, You grant us the right to provide the information to payment processing
                third parties for purposes of facilitating the completion of Your Order.
              </p>
              <p className={"sub-title"}>Order Cancellation</p>
              <p>
                We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not
                limited to:
              </p>
              <ul>
                <li>Goods availability</li>
                <li>Errors in the description or prices for Goods</li>
                <li>Errors in Your Order</li>
              </ul>
              <p>
                We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction
                is suspected.
              </p>
            </div>
          </div>

          <div className={"detail"}>
            <p className={"left"}>Your Order Cancellation Rights</p>
            <div className={"right"}>
              <p>
                Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our
                Returns Policy.
              </p>

              <p>
                Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn
                more about your right to cancel Your Order.
              </p>

              <p>
                Your right to cancel an Order only applies to Goods that are returned in the same condition as You
                received them. You should also include all of the products instructions, documents and wrappings. Goods
                that are damaged or not in the same condition as You received them or which are worn simply beyond
                opening the original packaging will not be refunded. You should therefore take reasonable care of the
                purchased Goods while they are in Your possession.
              </p>
              <p>
                We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will
                use the same means of payment as You used for the Order, and You will not incur any fees for such
                reimbursement.
              </p>
              <p>You will not have any right to cancel an Order for the supply of any of the following Goods:</p>

              <ul>
                <li>The supply of Goods made to Your specifications or clearly personalized.</li>
                <li>
                  The supply of Goods which according to their nature are not suitable to be returned, deteriorate
                  rapidly or where the date of expiry is over.
                </li>
                <li>
                  The supply of Goods which are not suitable for return due to health protection or hygiene reasons and
                  were unsealed after delivery.
                </li>
                <li>
                  The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other
                  items.
                </li>
                <li>
                  The supply of digital content which is not supplied on a tangible medium if the performance has begun
                  with Your prior express consent and You have acknowledged Your loss of cancellation right.
                </li>
              </ul>

              <p className={"sub-title"}>Availability, Errors and Inaccuracies</p>
              <p>
                available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience
                delays in updating information regarding our Goods on the Service and in Our advertising on other
                websites.
              </p>
              <p>
                We cannot and do not guarantee the accuracy or completeness of any information, including prices,
                product images, specifications, availability, and services. We reserve the right to change or update
                information and to correct errors, inaccuracies, or omissions at any time without prior notice.
              </p>

              <p className={"sub-title"}>Prices Policy</p>
              <p>The Company reserves the right to revise its prices at any time prior to accepting an Order.</p>
              <p>
                The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any
                occurrence affecting delivery caused by government action, variation in customs duties, increased
                shipping charges, higher foreign exchange costs and any other matter beyond the control of the Company.
                In that event, You will have the right to cancel Your Order.
              </p>

              <p className={"sub-title"}>Payment</p>
              <p>
                All Goods purchased are subject to a one-time payment. Payment can be made through various payment
                methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or online
                payment methods (PayPal, for example).
              </p>
              <p>
                Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your
                card issuer. If we do not receive the required authorization, We will not be liable for any delay or
                non-delivery of Your Order.
              </p>
            </div>
          </div>

          <div className={"detail"}>
            <p className={"left"}>User Accounts</p>
            <div className={"right"}>
              <p>
                When You create an account with Us, You must provide Us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of Your account on Our Service.
              </p>

              <p>
                You are responsible for safeguarding the password that You use to access the Service and for any
                activities or actions under Your password, whether Your password is with Our Service or a Third-Party
                Social Media Service.
              </p>

              <p>
                You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming
                aware of any breach of security or unauthorized use of Your account.
              </p>
              <p>
                You may not use as a username the name of another person or entity or that is not lawfully available for
                use, a name or trademark that is subject to any rights of another person or entity other than You
                without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
              </p>
            </div>
          </div>

          <div className={"detail"}>
            <p className={"left"}>Content</p>
            <div className={"right"}>
              <p className={"sub-title"}>Your Right to Post Content </p>
              <p>
                Our Service allows You to post Content. You are responsible for the Content that You post to the
                Service, including its legality, reliability, and appropriateness.
              </p>

              <p>
                By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform,
                publicly display, reproduce, and distribute such Content on and through the Service. You retain any and
                all of Your rights to any Content You submit, post or display on or through the Service and You are
                responsible for protecting those rights. You agree that this license includes the right for Us to make
                Your Content available to other users of the Service, who may also use Your Content subject to these
                Terms.
              </p>

              <p>
                {" "}
                You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it
                and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on
                or through the Service does not violate the privacy rights, publicity rights, copyrights, contract
                rights or any other rights of any person.
              </p>
            </div>
          </div>
        </Content>
      </Container>
      <Footer width={"90%"} />
    </>
  );
};

const Container = styled.div`
  width: 85%;
  min-height: 50vh;
  margin: 0 auto;
  padding: 40px 0;
`;

const Head = styled.div`
  margin-bottom: 40px;
  h1 {
    color: #192d46;
    font-weight: bold;
    font-size: 40px;
    margin: 0;
  }
`;

const Content = styled.div`
  p,
  ul {
    margin: 0;
    font-size: 17px;
    color: #667085;
  }

  .p-margin {
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 10px;
  }

  .detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 40px;
    margin-bottom: 20px;

    .left {
      width: 20%;
      font-size: 20px;
      font-weight: bold;
      color: #192d46;
    }
    .right {
      width: 75%;

      p {
        margin-bottom: 20px;
      }
    }

    .sub-title {
      font-weight: 600;
      color: #192d46;
    }
  }

  @media (max-width: 830px) {
    .detail {
      display: block;

      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
`;

export default TermsOfUse;
