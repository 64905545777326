import styled from "styled-components";
import { getProgress } from "../../helpers/tripHelpers";

const TripStatus = ({ status }) => {
  return <Status status={status}>{getProgress(status).title}</Status>;
};

const Status = styled.span`
  //display: flex;
  //align-items: center;
  width: min-content;
  padding: 6px 12px;
  text-align: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #047a48;
  background: #f3f4f7;
  color: ${({ status }) => status === "ON_ROUTE" && "#047a48"};
  color: ${({ status }) => status === "PENDING" && "#cdaa7d"};
  color: ${({ status }) => status === "FLAGGED" && "#344055"};
  color: ${({ status }) => status === "FINISHED" && "#047a48"};
  color: ${({ status }) => status === "CANCELLED" && "#b42219"};
  color: ${({ status }) => status === "ACCEPTED" && "#197eb4"};
  background: ${({ status }) => status === "FLAGGED" && "#f3f4f7"};
  background: ${({ status }) => status === "PENDING" && "#fff8ef"};
  background: ${({ status }) => status === "ON_ROUTE" && "#ebfdf3"};
  background: ${({ status }) => status === "FINISHED" && "#ebfdf3"};
  background: ${({ status }) => status === "CANCELLED" && "#fdf3f3"};
  background: ${({ status }) => status === "ACCEPTED" && "#f3f8fd"};

  .space {
    margin: 0 5px;
  }
`;

export default TripStatus;
