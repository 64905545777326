import Banner from "../../components/layout/Home/Banner";
import Features from "../../components/layout/Home/Features";
import Move from "../../components/layout/Home/Move";
import Footer from "../../components/layout/Footer";
import CTASection from "../../components/layout/Home/CTASection";
import HowItWorks from "../../components/layout/Home/HowItWorks";
import Schedule from "../../components/layout/Home/Schedule";

const Home = () => {
  const data = [
    {
      title: "Instant Bookings",
      image: "/images/iphone.png",
      content: [
        "With our mobile and web application, you can request transportation services quickly and easily, based on your requirements",
      ],
    },
    {
      title: "Reliable and Secure Payments",
      image: "/images/payment.png",
      content: [
        "At Go Truck, we understand the importance of reliable and secure payments. That's why we've made our payment process easy and secure, ensuring that your transactions are always safe and secure.",
      ],
    },
    {
      title: "Ratings",
      image: "/images/rating.png",
      content: [
        "We take pride in providing our users with a high-quality service. Our rating system allows users to rate drivers based on their experience, providing valuable feedback that helps us improve our service.",
      ],
    },
    {
      title: "Live Tracking",
      image: "/images/track.png",
      content: [
        "Live tracking on GoTruck provides you with instant visibility into your shipments on a map, real-time status updates, and tracking throughout the entire journey",
      ],
    },
  ];

  return (
    <>
      <div className="space-y-20 lg:space-y-32">
        <Banner />
        <Features />
        <CTASection
          title1="We make scheduling for"
          title2="you easier than ever"
          btnText="Request Trip"
          btnLink="/login"
          image="/images/request.png"
          text="Go Truck for Users is an easy-to-use platform that provides on-demand land freight transportation services to individuals and businesses. Our platform provides users with a seamless experience, enabling them to request transportation services, and track their shipments in real-time."
          route
        />
        <Schedule data={data} title="We make scheduling for you easier than ever" />
        <CTASection
          title1="Powering your business"
          title2="forward with Go Truck"
          btnText="Learn More"
          btnLink="/company"
          image="/images/signup.png"
          text="Go Truck for Companies is a powerful platform that enables businesses to manage their fleets of trucks, track logistics, and monitor income - all in one place. Our platform provides businesses with a comprehensive suite of features that simplify logistics management, streamline operations, and increase efficiency."
          route
        />
        <HowItWorks />
        <Move width={"100%"} />
      </div>
      <Footer width={"90%"} />
    </>
  );
};

export default Home;
