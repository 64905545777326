import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { Table, Input, Pagination, Button, Dropdown, Menu } from "antd";
import { IoFilter } from "react-icons/io5";
import TripStatus from "../../components/common/TripStatus";
import TripSummaryModal from "../../components/common/TripSummaryModal";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import EstimatorModal from "../../components/common/EstimatorModal";
import { TRIP_STATE } from "../../constants/trip";
import usePageTitle from "../../components/hooks/usePageTitle";

const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return null;
  }
  if (type === "next") {
    return null;
  }
  return originalElement;
};

const TripList = (props) => {
  const [visible, setVisible] = useState(false);
  const [trips, setTrips] = useState(
    props?.past_trips?.map((trip) => {
      const newTrip = {
        id: `${trip?._id}`,
        key: `${trip?._id}`,
        start_address: trip?.start_address,
        end_address: trip?.end_address,
        container_id: trip?.container_number ? trip?.container_number : "N/A",
        status: trip?.state?.value,
        date: moment(`${trip?.start_time}`).format("MMMM D, YYYY"),
      };
      return newTrip;
    })
  );

  const [upcomingTrips, setUpcomingTrips] = useState(
    props?.upcoming_trips?.map((trip) => {
      const newTrip = {
        id: `${trip?._id}`,
        key: `${trip?._id}`,
        start_address: trip?.start_address,
        end_address: trip?.end_address,
        container_id: trip?.container_number ? trip?.container_number : "N/A",
        status: trip?.state?.value,
        date: moment(`${trip?.start_time}`).format("MMMM D, YYYY"),
      };
      return newTrip;
    })
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [trip, setTrip] = useState(props.trip);
  const [searchText, setSearchText] = useState("");
  const [filteredTrips, setFilteredTrips] = useState([]);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const { category } = useParams();

  // console.log(category);

  const getPastTripsData = async () => {
    try {
      const response = await props.getPastTrips();
      const trips = response?.payload?.data?.trips;
      // console.log(trips);
      let newTrips = [];
      if (trips) {
        trips.forEach((trip) => {
          const newTrip = {
            id: `${trip?._id}`,
            key: `${trip?._id}`,
            start_address: trip?.start_address,
            end_address: trip?.end_address,
            container_id: trip?.container_number ? trip?.container_number : "N/A",
            status: trip?.state?.value,
            date: moment(`${trip?.start_time}`).format("MMMM Do YYYY, h:mma"),
          };
          newTrips.push(newTrip);
        });

        setTrips([...newTrips]);
      }
      if (response?.error) {
        throw new Error(response);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getPastTripsData();
  }, []);

  const getUpcomingTripsData = async () => {
    try {
      const response = await props.getUpcomingTrips();
      const trips = response?.payload?.data?.trips;
      // console.log(trips, "upcoming!!");
      let newTrips = [];
      if (trips) {
        trips.forEach((trip) => {
          const newTrip = {
            id: `${trip?._id}`,
            key: `${trip?._id}`,
            start_address: trip?.start_address,
            end_address: trip?.end_address,
            container_id: trip?.container_number ? trip?.container_number : "N/A",
            status: trip?.state?.value,
            date: moment(`${trip?.start_time}`).format("MMMM Do YYYY, h:mma"),
          };
          newTrips.push(newTrip);
        });

        setUpcomingTrips([...newTrips]);
      }
      if (response?.error) {
        throw new Error(response);
      }
    } catch (error) {
      return error;
    }
  };

  const { update_trip_loading } = props;

  useEffect(() => {
    getUpcomingTripsData();
  }, [update_trip_loading]);

  const getTrip = async (id) => {
    setVisible(true);
    try {
      const res = await props.getTrip(id);
      // console.log(res?.payload?.data?.trip)
      setTrip(res?.payload?.data?.trip);

      if (res?.error) {
        throw new Error(res);
      }
    } catch (error) {
      setVisible(false);
      return error;
    }
  };

  const handleTripCancel = async (id) => {
    try {
      const res = await props.updateTripStatus(id, TRIP_STATE.CANCELLED);
      // console.log(res?.payload?.data);

      if (res?.error) {
        throw new Error(res);
      }
    } catch (error) {
      return error;
    }
  };

  const columns = [
    { title: "Container ID", dataIndex: "container_id", key: "container_id" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Status", dataIndex: "status", key: "status", render: (text, record) => <TripStatus status={text} /> },
    { title: "Pick up Location", key: "start_address", dataIndex: "start_address" },
    { title: "Drop off Location", key: "end_address", dataIndex: "end_address" },
    {
      title: "More",
      key: "id",
      dataIndex: "id",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => getTrip(text)}>View</Menu.Item>
              {category === "history" && <Menu.Item>Download</Menu.Item>}
            </Menu>
          }
          trigger={["click"]}
        >
          <FiMoreVertical size={20} style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const allTrips = category ? (category === "upcoming" ? upcomingTrips : trips) : [];

  usePageTitle(`${category === "upcoming" ? "Upcoming Trips" : "Trip History"}`);

  useEffect(() => {
    const filteredTrips = allTrips?.filter((row) => {
      return (
        row.start_address.toLowerCase().includes(searchText.toLowerCase()) ||
        row.end_address.toLowerCase().includes(searchText.toLowerCase()) ||
        row.status.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setPageNumber(1);
    setFilteredTrips(filteredTrips);
    return () => {
      setFilteredTrips(allTrips);
    };
  }, [searchText]);

  const mainData = searchText.length > 0 ? filteredTrips : allTrips;

  let paginatedTrips = paginate(mainData, pageNumber, pageSize);

  return (
    <StyledHistory>
      <Inner>
        <Head>
          <h1>{category === "upcoming" ? "Upcoming trips" : "Trip History"}</h1>
          <div className={"input-filter"}>
            <Input
              className={"input"}
              size="large"
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button>Download all</Button>
          </div>
        </Head>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                getTrip(record.id);
              },
            };
          }}
          columns={columns}
          dataSource={paginatedTrips}
          pagination={false}
        />
        {mainData?.length > 5 && (
          <PagContainer>
            <Button
              onClick={() => {
                if (pageNumber === 1) {
                  return;
                }
                setPageNumber((pageNumber) => pageNumber - 1);
              }}
              style={{ opacity: pageNumber === 1 ? 0 : 1 }}
            >
              <AiOutlineArrowLeft className={"icon1"} />
              Previous
            </Button>
            <Pagination
              total={mainData.length}
              itemRender={itemRender}
              showSizeChanger={false}
              current={pageNumber}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
            <Button
              onClick={() => {
                if (pageNumber >= mainData.length / pageSize) {
                  return;
                }
                setPageNumber((pageNumber) => pageNumber + 1);
              }}
              style={{ opacity: pageNumber >= mainData.length / pageSize ? 0 : 1 }}
            >
              Next
              <AiOutlineArrowRight className={"icon2"} />
            </Button>
          </PagContainer>
        )}
      </Inner>
      <TripSummaryModal
        loading={props.loading_trip}
        visible={visible}
        closeModal={() => setVisible(false)}
        data={trip}
        ongoing={false}
      />
    </StyledHistory>
  );
};

const StyledHistory = styled.div`
  padding: 40px 0;
`;

const Inner = styled.div`
  width: 90%;
  margin: 0 auto;

  th.ant-table-cell::before {
    display: none;
  }

  td.ant-table-cell {
    font-size: 12px;
  }

  .ant-table {
    margin-bottom: 30px;
  }

  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-item-active {
    background: #feeee2;
  }

  .ant-table-row {
    cursor: pointer;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
    width: 300px;
    height: 40px;
    border-radius: 5px;
  }

  button {
    height: 40px;
    border-radius: 5px;
    margin-left: 20px;
  }
  .input-filter {
    display: flex;
  }

  .filters {
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    font-size: 15px;
    border-radius: 5px;
    height: 40px;
    width: 100px;
  }

  .icon {
    margin-right: 5px;
  }

  @media (max-width: 550px) {
    display: block;

    .input {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const PagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .icon1 {
    margin-right: 5px;
  }
  .icon2 {
    margin-left: 5px;
  }

  @media (max-width: 550px) {
    button {
      display: none;
    }
  }
`;

const mapStateToProps = ({ user, past_trips, trip, upcoming_trips, loading_trip, update_trip_loading }) => ({
  user,
  past_trips,
  trip,
  upcoming_trips,
  loading_trip,
  update_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripList);
