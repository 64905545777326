import Move from "../components/layout/Home/Move";
import Footer from "../components/layout/Footer";
import CTASection from "../components/layout/Home/CTASection";
import Schedule from "../components/layout/Home/Schedule";
import { config } from "../config";
import CompanyBanner from "../components/layout/Home/CompanyBanner";
import usePageTitle from "../components/hooks/usePageTitle";

const Company = () => {
  usePageTitle("Company");

  const data = [
    {
      title: "Logistics Tracking",
      image: "/images/logistics-tracking.png",
      content: [
        "Companies can monitor the progress of trips, view current location information, and receive updates on estimated arrival times.",
      ],
    },
    {
      title: "Fleet Management",
      image: "/images/logistics-tracking.png",
      content: [
        "We provide comprehensive fleet management capabilities, allowing companies to effectively manage their fleet of trucks. This includes tracking vehicle availability and assigning drivers to specific trucks.",
      ],
    },
    {
      title: "Income Monitoring",
      image: "/images/logistics-tracking.png",
      content: [
        "The platform allows companies to track their income and revenue generated from transportation services. They can view trip-specific earnings, analyze financial performance, and generate reports for accounting and analysis purposes.",
      ],
    },
    {
      title: "Reporting and Analytics",
      image: "/images/logistics-tracking.png",
      content: [
        "Go Truck offers reporting and analytics functionalities to help companies gain valuable insights into their transportation operations.",
      ],
    },
  ];

  return (
    <>
      <div className="space-y-20 lg:space-y-32">
        <CompanyBanner />
        <CTASection
          title1="Companies in Haulage"
          title2="Transports"
          btnText="Sign up as Company"
          btnLink={`${config.REACT_APP_GOTRUCK_COMPANY}/register_company`}
          image="/images/company-cta.png"
          text="Go Truck for Companies is a powerful platform that enables businesses to manage their fleets of trucks, track logistics, and monitor income - all in one place. Our platform provides businesses with a comprehensive suite of features that simplify logistics management, streamline operations, and increase efficiency."
        />
        <Schedule data={data} title="Explore Go Truck Core Features for Haulage Companies" company />
        <CTASection
          title1="Why You Should Partner"
          title2="With Us"
          btnText="Partner with us"
          btnLink="/partner"
          image="/images/company-cta2.png"
          text="Go Truck for Companies is a powerful platform that enables businesses to manage their fleets of trucks, track logistics, and monitor income - all in one place. Our platform provides businesses with a comprehensive suite of features that simplify logistics management, streamline operations, and increase efficiency."
          route
        />
        <Move width={"100%"} />
      </div>
      <Footer width={"90%"} />
    </>
  );
};

export default Company;
