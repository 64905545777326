import { useNavigate } from 'react-router-dom'
import {useEffect} from "react";


const Logout = () => {
    const navigate = useNavigate();

    useEffect(() =>{
        localStorage.removeItem('access_token')
        navigate('/login', { replace: true })
    },[])


    return null
}

export default Logout