import { useState, useEffect } from "react";
import styled from "styled-components";
// import Modal from "react-awesome-modal";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { Steps, Modal } from "antd";
import ContainerDetailsForm from "./ContainerDetailsForm";
import LocationForm from "./LocationForm";
import TdoInformation from "./TdoInformation";
import ScrollTop from "../ScrollTop";
import moment from "moment";
import { TRIP_TYPE } from "../../../constants/trip";

const { Step } = Steps;

const Index = ({
  visible,
  closeModal,
  createCost,
  setVisible2,
  setVisible,
  data,
  setData,
  startDate,
  setStartDate,
  tdoExpiryDate,
  setTdoExpiryDate,
  startAddress,
  setStartAddress,
  endAddress,
  setEndAddress,
  dropOffAddress,
  setDropOffAddress,
  imageUrl,
  setImageUrl,
  cargoImage,
  setCargoImage,
  estimatedPrice,
  setEstimatedPrice,
  estimatedUpperPrice,
  setEstimatedUpperPrice,
  costId,
  setCostId,
  startCoordinates,
  setStartCoordinates,
  endCoordinates,
  setEndCoordinates,
  dropOffCoordinates,
  setDropOffCoordinates,
  currentStep,
  setCurrentStep,
  setVisibleTerminalList,
  setTerminalOpener,
}) => {
  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleModalClose = () => {
    setData({
      trip_category: "TERMINAL_LOCATION",
      item_size: "",
      is_container_owner: false,
    });
    setCurrentStep(0);
    setStartDate(new Date());
    setStartAddress("");
    setEndAddress("");
    setDropOffAddress("");
    setStartCoordinates({ lat: null, lng: null });
    setEndCoordinates({ lat: null, lng: null });
    setDropOffCoordinates({ lat: null, lng: null });

    closeModal();
  };

  const handleStepChange = (step) => {
    const stepElement = document.getElementById("icon");
    stepElement.scrollIntoView();
    setCurrentStep(step);
  };

  const getCostId = async () => {
    if (!startCoordinates.lat || !endCoordinates.lat || !data.trip_category || !data.item_size) {
      return;
    }
    const tripData = {
      start_location: [startCoordinates?.lng, startCoordinates?.lat],
      end_location: [endCoordinates?.lng, endCoordinates?.lat],
      item_size: data?.item_size,
      trip_category: data.cargo_type === "CONTAINER" ? data.trip_category : TRIP_TYPE.LOCATION_LOCATION,
      offloading_duration: 86400000 * +data?.offloading_duration,
      is_container_owner: data?.cargo_type === "LOOSE_GOODS" ? true : data?.is_container_owner,
      tdo_valid_until: moment().add(3, "days"),
      //   start_address: startAddress,
      //   end_address: endAddress,
      //   drop_off_address: dropOffAddress,
      tonnage: data?.tonnage,
    };

    try {
      const res = await createCost(tripData);
      //   console.log(res, "response");
      if (res?.error) {
        throw new Error(res?.error);
      }
      setCostId(res?.payload?.data?.cost?._id);
      setEstimatedPrice(res?.payload?.data?.cost?.price);
      setEstimatedUpperPrice(res?.payload?.data?.cost?.price_upper_bound);
      return;
    } catch (error) {
      //   console.log(error, "error");
      return error;
    }
  };

  // useEffect(getCostId, [startCoordinates, endCoordinates, data]);

  //   console.log(data);

  return (
    <Modal
      visible={visible}
      width={1000}
      height={"90%"}
      style={{ top: 20 }}
      centered
      onCancel={handleModalClose}
      footer={""}
    >
      <Inner>
        {/* <Head id={"icon"}>
          <AiOutlineClose size={21} onClick={handleModalClose} className={"icon"} />
        </Head> */}
        <Steps size="large" current={currentStep} className={"steps"} id={"steps"}>
          <Step title="Container Details" />
          <Step title="Select Location" />
          <Step title="Additional Information" />
        </Steps>
        <div className={"content"}>
          {currentStep === 0 && (
            <ContainerDetailsForm
              data={data}
              handleChange={handleChange}
              setData={setData}
              setCurrentStep={handleStepChange}
            />
          )}
          {currentStep === 1 && (
            <LocationForm
              data={data}
              handleChange={handleChange}
              setData={setData}
              setCurrentStep={handleStepChange}
              setStartAddress={setStartAddress}
              startAddress={startAddress}
              setStartCoordinates={setStartCoordinates}
              startCoordinates={startCoordinates}
              endAddress={endAddress}
              setEndAddress={setEndAddress}
              endCoordinates={endCoordinates}
              setEndCoordinates={setEndCoordinates}
              dropOffAddress={dropOffAddress}
              setDropOffAddress={setDropOffAddress}
              dropOffCoordinates={dropOffCoordinates}
              setDropOffCoordinates={setDropOffCoordinates}
              startDate={startDate}
              setStartDate={setStartDate}
              setVisibleTerminalList={setVisibleTerminalList}
              setTerminalOpener={setTerminalOpener}
              getCostId={getCostId}
            />
          )}
          {currentStep === 2 && (
            <TdoInformation
              data={data}
              setData={setData}
              handleChange={handleChange}
              setCurrentStep={handleStepChange}
              tdoExpiryDate={tdoExpiryDate}
              setTdoExpiryDate={setTdoExpiryDate}
              setImageUrl={setImageUrl}
              imageUrl={imageUrl}
              cargoImage={cargoImage}
              setCargoImage={setCargoImage}
              estimatedPrice={estimatedPrice}
              estimatedUpperPrice={estimatedUpperPrice}
              setVisible={setVisible}
              setVisible2={setVisible2}
              costId={costId}
              getCostId={getCostId}
            />
          )}
        </div>
      </Inner>
    </Modal>
  );
};

const Inner = styled.div`
  z-index: 20;
  overflow: auto;
  min-height: 350px;
  padding: 0 50px 30px;

  .steps {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0 50px;
  }

  .content {
    width: 85%;
    margin: 0 auto;
  }

  @media (max-width: 300px) {
    .content {
      width: 100%;
    }
  }
`;
const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  .icon {
    cursor: pointer;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Index);
