import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import concatenateReducers from "redux-concatenate-reducers";
import { applyMiddleware, createStore } from "redux";
import { ACCESS_TOKEN } from "./constants";
import mainReducer from "./reducers/mainReducer";
import { config } from "../config";

const rootReducer = concatenateReducers([mainReducer]);
const signature = config.REACT_APP_GOTRUCK_SIGNATURE;

export const axios_client = axios.create({
  baseURL: config.REACT_APP_BASEURL,
  responseType: "json",
  timeout: 180000,
});

axios_client.interceptors.request.use(
  (configuration) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      configuration.headers.common["Authorization"] = localStorage.getItem(ACCESS_TOKEN);
    }
    //exclude our signature from Paystack requests
    if (!configuration.baseURL.includes(config.REACT_APP_PAYSTACK_URL)) {
      configuration.headers.common["x-gotruck-signature"] = signature;
    }

    return configuration;
  },

  (error) => Promise.reject(error)
);

axios_client.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
      if (error.response.data.status === 4011) {
        // console.log(axios.defaults.headers);
        (async () => {
          axios.defaults.headers.common["Authorization"] = null;
          localStorage.removeItem(ACCESS_TOKEN);
        })();
      }
    }
    return Promise.reject(error);
  }
);

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(axiosMiddleware(axios_client))));

export default store;
