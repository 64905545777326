import unlocked from "../../assets/unlocked.jpg";
import Modal from "react-awesome-modal";
import { Inner } from "../../styles/verification";
import { Button } from "antd";

const VerifiedModal = ({ visible, closeModal, setTriggerNext }) => {
  const handleProceed = () => {
    closeModal();
    setTriggerNext((prevState) => !prevState);
  };

  return (
    <Modal visible={visible} width="650" height="650" effect="fadeInUp" onClickAway={() => {}}>
      <Inner>
        <img src={unlocked} alt={"unlocked"} />
        <h1>Your Email has been verified</h1>
        <p>
          Verification completed. Proceed to enjoy <br /> our seamless services
        </p>
        <Button type="primary" onClick={handleProceed}>
          Proceed
        </Button>
      </Inner>
    </Modal>
  );
};

export default VerifiedModal;
