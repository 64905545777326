import styled from "styled-components";
import { Button } from "antd";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/go-logo.svg";
import { BiMenuAltRight } from "react-icons/bi";
import useWindowSize from "../../hooks/useWindowSize";
import { useState } from "react";
import MobileListItem from "./Home/MobileListItem";
import { navLinks } from "../../helpers/data";
import toast from "react-hot-toast";
// import Button from "../common/Button";

const Navbar = () => {
  const [activeNav, setActiveNav] = useState(false);

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const handleNav = (path) => {
    process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? toast.error("Coming soon!") : navigate(`${path}`);
  };

  return (
    <Nav>
      <Inner>
        <NavLink to={"/"}>
          <img src={logo} alt={"logo"} className="max-h-8" />
        </NavLink>
        {width > 730 && (
          <div className="flex items-center gap-10 list-none">
            {navLinks.map(({ name, path }, i) =>
              path == "/price-estimator" ? (
                <a
                  className={`hover:text-primary ${path === pathname ? "text-primary" : "text-black"}`}
                  onClick={() => handleNav(path)}
                  key={path}
                >
                  {name}
                </a>
              ) : (
                <li key={i}>
                  <NavLink
                    to={path}
                    className={`hover:text-primary ${path === pathname ? "text-primary" : "text-black"}`}
                  >
                    {name}
                  </NavLink>
                </li>
              )
            )}
          </div>
        )}
        {width > 730 && (
          <Actions>
            <Item>
              {process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? (
                <a onClick={() => handleNav("")}>Sign up</a>
              ) : (
                <NavLink to={"/signup"}>Sign up</NavLink>
              )}
            </Item>
            <Button onClick={() => navigate("/login")} type="primary">
              Log in
            </Button>
          </Actions>
        )}
        {width < 730 && (
          <Burger onClick={() => setActiveNav((activeNav) => !activeNav)}>
            <div className={activeNav ? "rotate1 one" : "one"}></div>
            <div className={activeNav ? "rotate2 two" : "two"}></div>
            <div className={activeNav ? "rotate3 three" : "three"}></div>
          </Burger>
        )}
        {
          <div className={activeNav ? "navOpen mobile" : "mobile"} onClick={() => setActiveNav(false)}>
            {navLinks.map(({ name, path }, i) =>
              path == "/price-estimator" ? (
                <a onClick={() => handleNav(path)} key={path}>
                  {name}
                </a>
              ) : (
                <NavLink key={i} to={path}>
                  {name}
                </NavLink>
              )
            )}

            {process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? (
              <a onClick={() => handleNav("")}>Sign up</a>
            ) : (
              <Button className={"btn-signup"} onClick={() => navigate("/signup")}>
                Sign up
              </Button>
            )}
            <Button type={"primary"} onClick={() => navigate("/login")}>
              Log in
            </Button>
          </div>
        }
      </Inner>
    </Nav>
  );
};

const Nav = styled.nav`
  padding: 18px 0px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fafafa;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 0 auto;

  a {
    text-decoration: none;
  }

  button {
    border-radius: 5px;
  }

  .mobile {
    background: ${({ theme }) => theme.colors.first};
    height: 90vh;
    width: 100%;
    position: fixed;
    margin: 0;
    top: 10vh;
    right: 100%;
    transition: transform 0.5s ease;
    overflow: hidden;
    z-index: 10;
    padding: 50px 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      font-weight: 550;
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 18px;
      margin-bottom: 30px;
    }

    button {
      margin-top: 30px;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      font-weight: bold;
      box-sizing: border-box;
      font-size: 18px;
    }

    .btn-signup {
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    .btn-signup:hover {
      background: ${({ theme }) => theme.colors.first};
    }
  }

  .navOpen {
    transform: translateX(100%);
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  margin-right: 10px;
`;

const Burger = styled.div`
  //position: relative;
  cursor: pointer;

  div {
    background: rgb(28, 28, 28);
    border-radius: 1px;
    display: block;
    transition: all 0.2s ease;
  }
  .one {
    padding: 1.3px 7px;
    width: 60%;
    margin-bottom: 0.3rem;
  }

  .three {
    padding: 1.3px 13px;
    width: 100%;
  }

  .two {
    padding: 1.3px 13px;
    width: 100%;
    margin-bottom: 0.3rem;
  }

  .rotate1 {
    transform: rotateZ(40deg) translateY(400%);
    width: 100%;
  }
  .rotate2 {
    opacity: 0;
  }
  .rotate3 {
    transform: rotateZ(-40deg) translateY(-400%);
  }
`;
export default Navbar;
