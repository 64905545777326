import { motion } from "framer-motion";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const AccordionItem = ({ item: { title, content }, index, activeIndex, setActiveIndex }) => {
  const isOpen = activeIndex === index;
  const arrowIcon = activeIndex === index ? <BiChevronDown className="text-xl" /> : <BiChevronUp className="text-xl" />;

  const handleSetIndex = (index) => {
    if (!isOpen) {
      setActiveIndex(index);
    }
    if (isOpen) {
      setActiveIndex(0);
    }
  };

  return (
    <div className="h-full" style={{ borderBottom: "0.5px solid #e8e8e8" }}>
      <div
        className={`${
          isOpen ? "" : ""
        }  flex cursor-pointer items-center justify-between pr-4 py-2 border-b border-primary transition-all duration-300 md:pr-6`}
        onClick={() => handleSetIndex(index)}
      >
        <h2 className="text-base font-[500] xl:text-lg text-[#D9D9D9]">{title}</h2>
        <span>{arrowIcon}</span>
      </div>

      {/* content */}
      <motion.div
        initial={false}
        className="overflow-hidden"
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.3, ease: [0.7, 0, 0.3, 1] }}
      >
        <div className="w-full space-y-2 pr-4 pb-2 text-sm md:pr-6 xl:text-base">
          {content.map((item, i) => (
            <p key={item + i} className="font-light leading-6 text-[#FFFFFFBF]">
              {item}
            </p>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default AccordionItem;
