import { useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import ChangePassword from "./ChangePassword";
import PaymentMethod from "./PaymentMethod";
import usePageTitle from "../../../components/hooks/usePageTitle";
// import Profile from "./Profile";

const { TabPane } = Tabs;

const Settings = () => {
  const [active, setActive] = useState("payment method");

  usePageTitle("Settings");

  const handleChange = (key) => {
    setActive(key);
  };
  return (
    <StyledSettings>
      <Inner>
        <h1>Settings</h1>
        <Tabs defaultActiveKey={active} onChange={handleChange}>
          {/* <TabPane tab="Profile" key="profile">
            <Profile />
          </TabPane> */}
          <TabPane tab="Payment Method" key="payment method">
            <PaymentMethod />
          </TabPane>
          <TabPane tab="Password" key="password">
            <ChangePassword />
          </TabPane>
        </Tabs>
      </Inner>
    </StyledSettings>
  );
};

const StyledSettings = styled.div`
  padding: 60px 0;
`;

const Inner = styled.div`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export default Settings;
